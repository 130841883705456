import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import VueRouter from 'vue-router'
import router from './router/index.js'
import './api/index.js'
import store from './store/index.js'
// import modes from './api/modes.js'
Vue.use(ElementUI);
Vue.use(VueRouter);



new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});

//全局获取用户信息
store.dispatch("whoAmI")