<template>
	<div>
		<div class="members-header">
			<!-- <div class="members-header-div">
				<el-row>
					<el-col :span="1">
						<el-image style="width: 24px;height: 24px;margin: 8px 0 0 20px;"
							:src="require('../assets/views/icon_news.png')">
						</el-image>
		
					</el-col>
					<el-col style="color: #fff;" :span="23">
						重要通知：关于无人可敌嗯嗯呢i点击>>
					</el-col>
				</el-row>
			</div> -->
			<div class="members-header-div">
				<el-row>
					<el-col :span="1">
						<el-image style="width: 24px;height: 24px;margin: 8px 0 0 20px;"
							:src="require('../assets/views/icon_news.png')">
						</el-image>
			
					</el-col>
					<el-col :span="23">
						<el-carousel height="40px" direction="vertical" indicator-position="none">
							<el-carousel-item v-for="(item,index) in list_info" :key="index">
								<!-- <h3 class="medium">{{ index }}</h3> -->
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/1/'+item.catId+'?mid='+item.id">
									<span style="color: #fff;">{{ item.name }}</span>
								</router-link>
							</el-carousel-item>
						</el-carousel>
			
						<!-- 重要通知：关于无人可敌嗯嗯呢i点击>> -->
					</el-col>
				</el-row>
			</div>
		</div>
		<div style="width: 1170px;margin: 20px auto;" class="members-tabs">
			<el-row  :gutter="60">
				<el-col :span="7">
					<el-menu :default-active="path" router>
						<el-menu-item index="1" disabled>
							<el-image style="height: 26px;margin-right: 10px;"
								:src="require('../assets/views/icon_vip-4.png')">
							</el-image>
							<span style="color: #FFFFFF;" slot="title">会员中心</span>
						</el-menu-item>
						<el-menu-item index="/members/login">
							<el-image style="height: 18px;margin-right: 10px;"
								:src="require('../assets/views/icon_list@2x-1.png')">
							</el-image>
							<span slot="title">会员登录</span>
						</el-menu-item>
						<el-menu-item index="/members/registered">
							<el-image style="height: 18px;margin-right: 10px;"
								:src="require('../assets/views/icon_list@2x-1.png')">
							</el-image>
							<span slot="title">会员注册</span>
						</el-menu-item>
						
						
					</el-menu>
				</el-col>
				<el-col :span="17">
					<router-view></router-view>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import {
		getArticleCategoryList
	} from '../api/interface.js'
	export default {
		data() {
			return {
				path: "",
				list_info:[]
			}
		},
		
		created() {
			this.onRouteChanged()
			this.getMenuList()
		},
		watch: {
			//监剛路由变化，只要变化7就调用获职路由参数方法将数据存储本组件即可
			'$route': 'onRouteChanged',
		},
		methods: {
			getMenuList() {
				getArticleCategoryList({
					catId: 2,
					pageNo: 1,
					pageSize: 3
				}).then(res => {
					this.list_info = res.data.data.articlePage.rows
					// console.log(this.list_info)
				})
			},
			
			onRouteChanged() {
				let that = this
					
				that.path = that.$route.path;
				
			}
	
		}
	}
</script>

<style>
</style>
