<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		created() {
			this.type = 1
			this.$message({
				message: '退出成功！',
				type: 'success',
				duration: 1000,
				onClose: () => {
					this.$store.commit('setIsLogin', false);
					window.sessionStorage.removeItem('userId');
					this.$router.push('/cIndex')
				}
			})
		
		},
	}
</script>

<style>
</style>
