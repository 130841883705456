<template>
	<div v-loading="loading">
		<div id="dhxg" style="position: absolute;top: 0;left: 0;" v-if="gggdimg != ''">
			<a style="text-decoration: none;color: inherit;cursor: pointer;" target="_blank" :href="gggdlink">
				<el-image style="height: 180px;width: 250px;" :src="gggdimg">
					<div slot="error" class="image-slot">
						
					</div>
				</el-image>
			</a>
			<i class="el-icon-close" style="position: absolute;top: 5px;right: 5px;color: #FFFFFF;cursor: pointer;"
				@click="closegg"></i>
		</div>
		<div class="home-header">
			<div style="position: relative;height: 280px;">
				<el-image style="height: 280px;width: 100%;" :src="site_banner">
					<div slot="error" class="image-slot">

					</div>
				</el-image>
				<div
					style="width: 100%;color: #FFFFFF;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;position: absolute;bottom: 80px;z-index: 999;left: 360px;">
					<div>
						<div class="white" style="font-size: 25px;">
							欢迎来到广东省水利水电行业协会
						</div>
						<br>
						<el-input v-model="input" style="width: 20%;" size="medium" placeholder="请输入您要搜索的内容">
						</el-input>
						<el-button @click="search()" size="medium" type="primary">搜索</el-button>
					</div>
				</div>
			</div>
			<!-- <el-carousel style="position: relative;" trigger="click" height="300px" arrow="never"
				indicator-position="none">
				<el-carousel-item v-for="(item,index) in msg" :key="index">
					<a style="text-decoration: none;color: inherit;" target="_blank" :href="item.showLink">
						<el-image style="height: 280px;width: 100%;" :src="item.showImgUrl">
							<div slot="error" class="image-slot">

							</div>
						</el-image>
					</a>
					

				</el-carousel-item>
				
			</el-carousel> -->

		</div>
		<div class="floating-window" :style="{ left: posX + 'px', top: posY + 'px' }" v-show="shopshow">
			<a href="http://gdwater.traneasy.cn/User/Login/login">
			<el-image style="width: 150px;height: 150px;" :src="require('../assets/views/111.jpg')">
			</el-image>
			</a>
			<div style="color:#fff;position:absolute;top:0px;right:5px;z-index:99999;" @click="div_hide">X</div>
		</div>
		<div style="width: 100%;background-color: rgb(250, 251, 251);margin-top: -20px;position: relative;">
			<!-- 飘窗 -->
			<!-- <a href="http://gdwater.traneasy.cn/User/Login/login"> -->
				
				<!-- <div style="position: absolute;top: 200px;right: 100px;font-size: 12px;width: 120px;height: 120px;text-align: center;line-height: 120px;border-radius: 10px;background-color: rgb(64, 158, 255);color: #fff;box-shadow: #ccc 3px 4px 5px;">文明工地申报系统</div> -->
			<!-- </a> -->
			<div style="width: 1170px;margin: 0 auto;margin-top: 20px;overflow: hidden;">
				<div class="home-main">
					<el-row>
						<el-col :span="1">
							<el-image style="width: 24px;height: 24px;margin: 8px 0 0 20px;"
								:src="require('../assets/views/icon_news-1.png')">
							</el-image>

						</el-col>
						<el-col :span="23">
							<el-carousel height="40px" direction="vertical" indicator-position="none">
								<el-carousel-item v-for="(item,index) in list[2].articleModels" :key="index">
									<!-- <h3 class="medium">{{ index }}</h3> -->
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[2].parentId+'/'+list[2].catId+'?mid='+item.id">{{ item.name }}
									</router-link>
								</el-carousel-item>
							</el-carousel>

						</el-col>
					</el-row>

				</div>
				<el-container style="height: 400px;">
					<el-aside style="width: 510px;height: 400px;overflow: hidden;">
						<el-carousel style="position: relative;" trigger="click" height="320px" arrow="never"
							indicator-position="none">
							<el-carousel-item v-for="(item,index) in list['-1'].articleModels" :key="index">
								<div>
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list['-1'].parentId+'/'+list['-1'].catId+'?mid='+item.id">
										<el-image style="width: 100%;height: 320px;" :src="item.imageUrl">
											<div slot="error" class="image-slot">

											</div>
										</el-image>
										<div
											style="width: 100%;background-color: rgb(5,116,207,0.6);height: 60px;line-height: 60px;text-align: center;color: #FFFFFF;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;position: absolute;bottom: 0;">
											{{ item.name }}
										</div>
									</router-link>
								</div>

							</el-carousel-item>
						</el-carousel>
						<div style="height: 70px;margin-top: 10px;">
							<router-link style="text-decoration: none;color:  inherit;"
								:to="'/'+list[70].parentId+'/'+list[70].catId">
								<el-image style="width: 100%;height: 70px;" :src="list[70].bigImageUrl">
								</el-image>
							</router-link>
						</div>
					</el-aside>
					<el-main style="padding: 0;margin-left: 20px;height: 400px;">

						<div style="height: 400px;">
							<div style="height: 400px;" class="xhd">
								<el-card class="clearfix" style="height: 398px;">
									<div slot="header">
										<span>
											<el-image style="height: 25px;width: 25px;"
												:src="require('../assets/views/icon_news-44.png')">
											</el-image>
											<h3 style="display: inline-block;vertical-align: top;margin-left: 10px;">
												协会动态</h3>
										</span>
										<div style="float: right;cursor: pointer;">
											<router-link style="text-decoration: none;color:  inherit;"
												:to="'/'+list[3].parentId+'/'+list[3].catId">
												<el-button
													style=" padding: 3px 0;color: #fff;font-size: 18px;margin-right: 5px;vertical-align: top;"
													type="text">更多
												</el-button>
												<el-image style="height: 25px;width: 25px;"
													:src="require('../assets/views/icon_more.png')">
												</el-image>
											</router-link>
										</div>



									</div>
									<div v-for="(item,index) in list[3].articleModels" :key="index">
										<el-row style="padding: 10px 0;">
											<el-col :span="18">
												<div style="display: flex; align-items: center;">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_list@2x-1.png')">
														</el-image>

														<router-link style="text-decoration: none;color:  inherit;"
															:to="'/'+list[3].parentId+'/'+list[3].catId+'?mid='+item.id">

															{{ item.name }}

														</router-link>
													</span>
													<template>
														<el-image v-if="item.isNew == 1"
															style="height: 11px;width: 24px;flex-shrink: 0;"
															:src="require('../assets/views/new.gif')">
														</el-image>
														<el-image v-if="item.isTop == 1"
															style="height: 15px;width: 28px;flex-shrink: 0;"
															:src="require('../assets/views/zhiding.png')">
														</el-image>
													</template>
												</div>

											</el-col>
											<el-col :span="6" style="text-align: right;">{{ format(item.addTime) }}
											</el-col>
										</el-row>
									</div>
								</el-card>
							</div>

							<!-- <div style="height: 70px;">
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/'+list[70].parentId+'/'+list[70].catId">
									<el-image style="width: 100%;height: 70px;" :src="list[70].bigImageUrl">
									</el-image>
								</router-link>
							</div> -->

						</div>

					</el-main>
				</el-container>
				<div style="width: 100%;height: 180px;margin-top: 30px;">
					<el-carousel trigger="click" height="180px">
						<el-carousel-item v-for="(item,index) in msg" :key="index">
							<a style="text-decoration: none;color: inherit;" target="_blank" :href="item.showLink">
								<el-image style="height: 180px;width: 100%;" :src="item.showImgUrl">
									<div slot="error" class="image-slot">

									</div>
								</el-image>
							</a>
						</el-carousel-item>
					</el-carousel>
					<!-- <el-carousel trigger="click" height="300px" arrow="never" indicator-position="none">
							<el-carousel-item v-for="(item,index) in msg" :key="index">
								<a style="text-decoration: none;color: inherit;" target="_blank" :href="item.showLink">
									<el-image style="height: 280px;width: 100%;" :src="item.showImgUrl">
										<div slot="error" class="image-slot">
				
										</div>
									</el-image>
								</a>
							</el-carousel-item>
						</el-carousel> -->
				</div>

				<el-container style="margin-top: 30px;height: 400px;">
					<el-aside width="60%">
						<el-row>
							<el-col :span="2">
								<div class="xhdt">
									<div style="width: 2em;margin: 0 auto;">
										<span>
											<el-image style="height: 26px;margin-top: 30px;width: 20px;"
												:src="require('../assets/views/icon_note.png')">
											</el-image>
											<h2 class="white" style="display: inline-block;margin-top: 5px;">{{list[2].catName}}
											</h2>

										</span>
										<div style="margin-top: 100px;cursor: pointer;">
											<router-link style="text-decoration: none;color:  inherit;"
												:to="'/'+list[2].parentId+'/'+list[2].catId">
												<span style="font-size: 20px;color: #fff;">
													更多
												</span>
												<el-image style="height: 20px;margin-top: 10px;width: 20px;"
													:src="require('../assets/views/icon_more.png')">
												</el-image>
											</router-link>
										</div>


									</div>

								</div>
							</el-col>

							<el-col :span="22">
								<el-card style="height: 390px;">
									<div v-for="(item,index) in list[2].articleModels" :key="index">
										<el-row style="padding: 14px 0;width: 100%;">
											<el-col :span="18">
												<div style="display: flex; align-items: center;">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
														<el-image
															style="height: 10px;margin-right: 10px;display: inline-block;width: 10px;"
															:src="require('../assets/views/icon_list@2x-1.png')">
														</el-image>
														<router-link style="text-decoration: none;color:  inherit;"
															:to="'/'+list[2].parentId+'/'+list[2].catId+'?mid='+item.id">

															{{ item.name }}
														</router-link>
													</span>
													<template>
														<el-image v-if="item.isNew == 1"
															style="height: 11px;width: 24px;flex-shrink: 0;"
															:src="require('../assets/views/new.gif')">
														</el-image>
														<el-image v-if="item.isTop == 1"
															style="height: 15px;width: 28px;flex-shrink: 0;"
															:src="require('../assets/views/zhiding.png')">
														</el-image>
													</template>
												</div>


											</el-col>
											<el-col :span="6" style="text-align: right;">{{ format(item.addTime) }}
											</el-col>
										</el-row>
									</div>
								</el-card>
							</el-col>
						</el-row>
					</el-aside>
					<el-main style="padding: 0;margin-left: 20px;">
						<span v-if="!isLogin">
							<el-card class="clearone" style="height: 315px;">
								<div slot="header">
									<span>
										<el-image style="height: 25px;width: 25px;"
											:src="require('../assets/views/icon_vip-4.png')">
										</el-image>
										<h3 style="display: inline-block;vertical-align: top;margin-left: 10px;">
											会员登录</h3>
									</span>
									<div style="float: right;cursor: pointer;">
										<router-link style="text-decoration: none;color:  inherit;"
											:to="'/members/login'">
											<el-button
												style="padding: 3px 0;color: #fff;vertical-align: top;font-size: 18px;margin-right: 5px;"
												type="text">会员中心
											</el-button>
											<el-image style="height: 25px;width: 25px;"
												:src="require('../assets/views/icon_more.png')">
											</el-image>
										</router-link>
									</div>


								</div>
								<div style="width: 90%;">
									<el-form ref="form" :model="form" :rules="rules" label-width="80px">
										<el-form-item label="用户名:" prop="name">
											<el-input v-model="form.name"></el-input>
										</el-form-item>
										<el-form-item label="密码:" prop="password">
											<el-input show-password v-model="form.password"></el-input>
										</el-form-item>
										<el-form-item>
											<el-button style="width: 45%;" @click="resetForm('form')">重置</el-button>
											<el-button style="background-color: rgb(243,137,57);color: #fff;width: 45%;"
												@click="onSubmit('form')">登录</el-button>
										</el-form-item>
									</el-form>
									<div style="font-size: 14px;margin-left: 80px;">
										<el-image style="height: 16px;vertical-align: top;width: 16px;"
											:src="require('../assets/views/icon_remain.png')">
										</el-image>
										还不是会员？立即<router-link to="/members/login"
											style="text-decoration: underline;cursor: pointer;color: rgb(48,118,211);">
											申请成为会员
										</router-link>
									</div>
								</div>
							</el-card>
						</span>
						<span v-else>
							<el-card class="clearone" style="height: 315px;">
								<div slot="header">
									<span>
										<el-image style="height: 25px;width: 25px;"
											:src="require('../assets/views/icon_vip-4.png')">
										</el-image>
										<h3 style="display: inline-block;vertical-align: top;margin-left: 10px;">
											入会申请</h3>
									</span>
									<div style="float: right;cursor: pointer;">
										<router-link style="text-decoration: none;color:  inherit;" :to="'/membersto'">
											<el-button
												style="padding: 3px 0;color: #fff;vertical-align: top;font-size: 18px;margin-right: 5px;"
												type="text">会员中心
											</el-button>
										</router-link>
										<el-image style="height: 25px;width: 25px;" :src="require('../assets/views/icon_more.png')">
										</el-image>
									</div>
								</div>
								<div style="height: 210px;">
									<div style="text-align: center;">
										<div>
											<i style="font-size: 50px;border: 2px solid #000;border-radius: 50%;padding: 10px;"
												class="el-icon-user"></i>
										</div>
										<p style="margin-top: 20px;">
											您好{{ JSON.parse(loginInfo).email }},欢迎登录协会官网！
										</p>
										<div style="margin-top: 30px;">
											<el-button style="width: 40%;margin-right: 5px;" @click="tcdl()">退出登录
											</el-button>
											<router-link style="text-decoration: none;color:  inherit;"
												:to="'/membersto'">
												<el-button
													style="background-color: rgb(243,137,57);color: #fff;width: 40%;margin-left: 5px;">
													申请入会</el-button>
											</router-link>
										</div>

									</div>

								</div>
							</el-card>
						</span>
						<router-link style="text-decoration: none;color:  inherit;" :to="'/'+list[68].catId+'/list'">
							<div
								style="background-color: rgb(48,118,211);color: #fff;height: 50px;line-height: 50px;text-align: center;font-size: 24px;margin-top: 25px;cursor: pointer;">
								法律咨询服务
							</div>
						</router-link>
					</el-main>
				</el-container>

			</div>
		</div>

		<div class="standard">
			<div style="position: relative; width: 1170px;margin: 0 auto;">
				<el-tabs v-model="activeName">
					<el-tab-pane :label="list[53].catName" name="a">
						<div style="width: 1170px;margin: 20px auto;">
							<el-card>
								<div class="cardto">
									<el-tabs v-model="activeNamefour" :stretch="true">
										<el-tab-pane v-for="(item,index) in list[53].modelList" :key="index"
											:name="item.catName">
											<span slot="label">
												<el-image style="height: 40px;vertical-align: top;width: 40px;"
													:src="item.imageUrl">
													<div slot="error" class="image-slot">

													</div>
												</el-image>
												<span
													style="vertical-align: top;display: inline-block;margin-left: 10px;">{{item.catName}}</span>
											</span>
											<el-divider></el-divider>
											<div>
												<el-row :gutter="120">
													<el-col :span="12" v-for="(itemto,indexto) in item.articleModels"
														:key="indexto">

														<el-row style="padding: 5px 0;">
															<el-col :span="18">
																<div style="display: flex; align-items: center;">
																	<span
																		style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
																		<el-image
																			style="height: 10px;margin-right: 10px;width: 10px;"
																			:src="require('../assets/views/icon_list@2x-1.png')">
																		</el-image>
																		<router-link
																			style="text-decoration: none;color:  inherit;"
																			:to="'/'+list[53].parentId+'/'+list[53].catId+'?mid='+itemto.id">

																			{{ itemto.name }}

																		</router-link>
																		<!-- {{ itemto.name }} -->
																	</span>
																	<template>
																		<el-image v-if="item.isNew == 1"
																			style="height: 11px;width: 24px;flex-shrink: 0;"
																			:src="require('../assets/views/new.gif')">
																		</el-image>
																		<el-image v-if="item.isTop == 1"
																			style="height: 15px;width: 28px;flex-shrink: 0;"
																			:src="require('../assets/views/zhiding.png')">
																		</el-image>
																	</template>
																</div>
															</el-col>
															<el-col :span="6" style="text-align: right;">
																{{ format(itemto.addTime) }}
															</el-col>
														</el-row>
													</el-col>

												</el-row>
											</div>

										</el-tab-pane>

									</el-tabs>
								</div>
							</el-card>
						</div>

					</el-tab-pane>
					<el-tab-pane :label="list[59].catName" name="b">
						<div style="width: 1170px;margin: 20px auto;">
							<el-card>
								<div class="cardto">
									<el-tabs v-model="activeNamefourto" :stretch="true">

										<el-tab-pane v-for="(item,index) in list[59].modelList" :key="index"
											:name="item.catName">
											<span slot="label">
												<el-image style="height: 40px;vertical-align: top;width: 40px;"
													:src="item.imageUrl">
													<div slot="error" class="image-slot">

													</div>
												</el-image>
												<span
													style="vertical-align: top;display: inline-block;margin-left: 10px;">{{item.catName}}</span>
											</span>
											<el-divider></el-divider>
											<div>
												<el-row :gutter="120">
													<el-col :span="12" v-for="(itemto,indexto) in item.articleModels"
														:key="indexto">

														<el-row style="padding: 5px 0;">
															<el-col :span="18">
																<div style="display: flex; align-items: center;">
																	<span
																		style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
																		<el-image
																			style="height: 10px;margin-right: 10px;width: 10px;"
																			:src="require('../assets/views/icon_list@2x-1.png')">
																		</el-image>
																		<router-link
																			style="text-decoration: none;color:  inherit;"
																			:to="'/'+list[53].parentId+'/'+list[53].catId+'?mid='+itemto.id">

																			{{ itemto.name }}

																		</router-link>
																	</span>
																	<template>
																		<el-image v-if="item.isNew == 1"
																			style="height: 11px;width: 24px;flex-shrink: 0;"
																			:src="require('../assets/views/new.gif')">
																		</el-image>
																		<el-image v-if="item.isTop == 1"
																			style="height: 15px;width: 28px;flex-shrink: 0;"
																			:src="require('../assets/views/zhiding.png')">
																		</el-image>
																	</template>
																</div>
															</el-col>
															<el-col :span="6" style="text-align: right;">
																{{ format(itemto.addTime) }}
															</el-col>
														</el-row>
													</el-col>

												</el-row>
											</div>

										</el-tab-pane>
									</el-tabs>
								</div>
							</el-card>
						</div>
					</el-tab-pane>
				</el-tabs>


				<div style="position: absolute;right: 0;top: 5px;cursor: pointer;">
					<router-link style="text-decoration: none;color:  inherit;"
						:to="'/'+list[53].parentId+'/'+list[53].catId">
						<span style="font-size: 20px;color: rgb(39,103,176);">更多</span>
						<el-image style="height: 25px;vertical-align: top;margin-left: 10px;width: 25px;"
							:src="require('../assets/views/icon_more_blue.png')">
						</el-image>
					</router-link>
				</div>
			</div>
		</div>
		<div style="width: 1170px;margin: 0 auto;">
			<div style="margin-top: 20px;" class="famd">
				<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_flower.png')">
				</el-image>
				<h2 style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
					{{list[35].catName}}
				</h2>
				<el-divider></el-divider>
			</div>
			<div>
				<el-row :gutter="60">
					<el-col style="margin-top: 20px;" v-for="(item,index) in list[35].modelList" :key="index" :span="8">
						<div style="width: 350px;height: 100px;">
							<router-link style="text-decoration: none;color:  inherit;"
								:to="'/'+list[35].catId+'/'+item.catId">
								<div style="position: relative;">

									<el-image style="width: 350px;height: 100px;" :src="item.bigImageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
									<div
										style="position: absolute;width: 300px;height: 60px;left: 25px;top: 20px;color: #FFFFFF;text-align: center;font-size: 22px;font-weight: bold;line-height: 60px;">
										{{item.catName}}
									</div>
								</div>
							</router-link>
						</div>

					</el-col>
					<!-- <el-col :span="8">
						<el-image style="width: 100%;" :src="require('../assets/views/pic2.png')">
						</el-image>
					</el-col>
					<el-col :span="8">
						<el-image style="width: 100%;" :src="require('../assets/views/pic3.png')">
						</el-image>
					</el-col>

					<el-col :span="8">
						<el-image style="width: 100%;" :src="require('../assets/views/pic4.png')">
						</el-image>
					</el-col>
					<el-col :span="8">
						<el-image style="width: 100%;" :src="require('../assets/views/pic5.png')">
						</el-image>
					</el-col>
					<el-col :span="8">
						<el-image style="width: 100%;" :src="require('../assets/views/pic6.png')">
						</el-image>
					</el-col> -->
				</el-row>
			</div>

			<div style="margin-top: 20px;" class="famd">
				<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_award.png')">
				</el-image>
				<h2 style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
					{{list[5].catName}}
				</h2>
				<el-divider></el-divider>
				<el-row :gutter="60">
					<el-col v-for="(item,index) in list[5].modelList" :key="index" :span="8" style="margin-top: 10px;">
						<div style="width: 350px;height: 100px;">
							<router-link style="text-decoration: none;color:  inherit;"
								:to="'/'+list[5].parentId+'/'+item.catId">
								<div style="position: relative;">

									<el-image style="width: 350px;height: 100px;" :src="item.bigImageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
									<div
										style="position: absolute;width: 300px;height: 60px;left: 25px;top: 20px;color: #FFFFFF;text-align: center;font-size: 22px;font-weight: bold;line-height: 60px;">
										{{item.catName}}
									</div>
								</div>
							</router-link>
						</div>
						<!-- <router-link style="text-decoration: none;color:  inherit;"
							:to="'/'+list[5].parentId+'/'+item.catId">
							
							<div class="imgtext">
								<div style="line-height: 80px;font-size: 22px;">
									{{item.catName}}
								</div>
						
							</div>

						</router-link> -->
					</el-col>

				</el-row>
			</div>

			<div style="margin-top: 20px;" class="famd">
				<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_product.png')">
				</el-image>
				<h2 style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
					{{list[67].catName}}
				</h2>
				<div style="cursor: pointer;float: right;">
					<router-link style="text-decoration: none;color:  inherit;" :to="'/'+list[67].catId+'/list'">
						<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
						<el-image style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
							:src="require('../assets/views/icon_more_blue.png')">
						</el-image>
					</router-link>
				</div>
				<el-divider></el-divider>
				<el-row :gutter="20">

					<el-col v-for="(item,index) in list[67].modelList" :key="index" :span="6">
						<div style="width: 275px;height: 100px;">
							<router-link style="text-decoration: none;color:  inherit;"
								:to="'/'+list[67].catId+'/'+item.catId">
								<div style="position: relative;">

									<el-image style="width: 275px;height: 100px;" :src="item.bigImageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
									<div
										style="position: absolute;width: 255px;height: 60px;left: 10px;top: 20px;color: #FFFFFF;text-align: center;font-size: 22px;font-weight: bold;line-height: 60px;">
										{{item.catName}}
									</div>
								</div>
							</router-link>
						</div>
						<!-- <router-link style="text-decoration: none;color:  inherit;"
							:to="'/'+list[67].catId+'/'+item.catId">
							
							<div class="imgtext">
								<div v-if="item.catName.length < 8" style="line-height: 80px;font-size: 22px;">
									{{item.catName}}
								</div>
								<div v-else>
									{{item.catName.slice(0,4)}}<br>
									{{item.catName.slice(4)}}
								</div>
							</div>
						
						</router-link> -->

					</el-col>

				</el-row>

				<!-- <span v-if="list[67].articleModels >= 5"> -->
				<!-- <div class="luto">
					<nav>
						<ul style="width: 10000%;" class="lutosj">
							<li v-for="(item,index) in list[67].articleModels" :key="index"
								style="float: left;margin-left: 10px;">
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/'+list[67].catId+'/list?mid='+item.id">
									<el-image style="width: 300px;height: 191px;" :src="item.imageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
								</router-link>
							</li>

						</ul>
					</nav>
				</div> -->
				<!-- </span>
				<span v-else>
					<el-row :gutter="50">
						<el-col style="margin-top: 20px;" v-for="(item,index) in list[67].articleModels" :key="index"
							:span="6">
							<div style="position: relative;">
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/'+list[67].catId+'/list?mid='+item.id">
									<el-image style="width: 100%;height: 191px;" :src="item.imageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
									<div class="dc">
										{{item.name}}
									</div>
								</router-link>
							</div>

						</el-col>

					</el-row>
				</span> -->




			</div>

			<div style="margin-top: 20px;">
				<el-row :gutter="50">
					<el-col :span="12">
						<div class="bosem" style="position: relative;">
							<el-tabs v-model="activeNamethere">
								<el-tab-pane :name="list[6].catName">
									<span slot="label">
										<el-image style="height: 40px;vertical-align: top;width: 40px;"
											:src="require('../assets/views/icon_news1.png')">
										</el-image>
										<span
											style="vertical-align: top;display: inline-block;margin-left: 10px;">{{list[6].catName}}</span>
									</span>
									<div style="margin: 5px;">
										<el-card>
											<div v-for="(item,index) in list[6].articleModels" :key="index">
												<el-row style="padding: 5px 0;">
													<el-col :span="18">
														<div style="display: flex; align-items: center;">
															<span
																style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
																<el-image style="height: 10px;margin-right: 10px;width: 10px;"
																	:src="require('../assets/views/icon_list@2x-1.png')">
																</el-image>
																<router-link
																	style="text-decoration: none;color:  inherit;"
																	:to="'/'+list[6].catId+'?mid='+item.id">

																	{{ item.name }}

																</router-link>
															</span>
															<template>
																<el-image v-if="item.isNew == 1"
																	style="height: 11px;width: 24px;flex-shrink: 0;"
																	:src="require('../assets/views/new.gif')">
																</el-image>
																<el-image v-if="item.isTop == 1"
																	style="height: 15px;width: 28px;flex-shrink: 0;"
																	:src="require('../assets/views/zhiding.png')">
																</el-image>
															</template>
														</div>
													</el-col>
													<el-col :span="6" style="text-align: right;">
														{{ format(item.addTime) }}
													</el-col>
												</el-row>
											</div>
										</el-card>
									</div>

								</el-tab-pane>
								<el-tab-pane :label="list[7].catName" name="b">
									<span slot="label">
										<span
											style="vertical-align: top;display: inline-block;margin-left: 10px;">{{list[7].catName}}</span>
									</span>
									<div style="margin: 5px;">
										<el-card>
											<div v-for="(item,index) in list[7].articleModels" :key="index">
												<el-row style="padding: 5px 0;">
													<el-col :span="18">
														<div style="display: flex; align-items: center;">
															<span
																style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
																<el-image style="height: 10px;margin-right: 10px;width: 10px;"
																	:src="require('../assets/views/icon_list@2x-1.png')">
																</el-image>
																<router-link
																	style="text-decoration: none;color:  inherit;"
																	:to="'/'+list[7].catId+'?mid='+item.id">

																	{{ item.name }}

																</router-link>
															</span>
															<template>
																<el-image v-if="item.isNew == 1"
																	style="height: 11px;width: 24px;flex-shrink: 0;"
																	:src="require('../assets/views/new.gif')">
																</el-image>
																<el-image v-if="item.isTop == 1"
																	style="height: 15px;width: 28px;flex-shrink: 0;"
																	:src="require('../assets/views/zhiding.png')">
																</el-image>
															</template>
														</div>
													</el-col>
													<el-col :span="6" style="text-align: right;">
														{{ format(item.addTime) }}
													</el-col>
												</el-row>
											</div>
										</el-card>
									</div>
								</el-tab-pane>
							</el-tabs>


							<div style="position: absolute;right: 0;top: 5px;cursor: pointer;">
								<router-link style="text-decoration: none;color:  inherit;" :to="'/'+list[6].catId">
									<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
									<el-image style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
										:src="require('../assets/views/icon_more_blue.png')">
									</el-image>
								</router-link>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_vip-5.png')">
								</el-image>
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[10].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[10].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card>
									<div v-for="(item,index) in list[10].articleModels" :key="index">
										<el-row style="padding: 5px 0;">
											<el-col :span="18">
												<div style="display: flex; align-items: center;">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_list@2x-1.png')">
														</el-image>
														<router-link style="text-decoration: none;color:  inherit;"
															:to="'/'+list[10].catId+'?mid='+item.id">

															{{item.name}}

														</router-link>
													</span>
													<template>
														<el-image v-if="item.isNew == 1"
															style="height: 11px;width: 24px;flex-shrink: 0;"
															:src="require('../assets/views/new.gif')">
														</el-image>
														<el-image v-if="item.isTop == 1"
															style="height: 15px;width: 28px;flex-shrink: 0;"
															:src="require('../assets/views/zhiding.png')">
														</el-image>
													</template>
												</div>
											</el-col>
											<el-col :span="6" style="text-align: right;">{{ format(item.addTime) }}
											</el-col>
										</el-row>
									</div>
								</el-card>
							</div>
						</div>

					</el-col>
				</el-row>
			</div>

			<div style="margin-top: 20px;" class="famd">
				<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_project.png')">
				</el-image>
				<h2 style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
					{{list[21].catName}}
				</h2>
				<div style="cursor: pointer;float: right;">
					<router-link style="text-decoration: none;color:  inherit;" :to="'/'+list[21].catId+'/list'">
						<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
						<el-image style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
							:src="require('../assets/views/icon_more_blue.png')">
						</el-image>
					</router-link>
				</div>
				<el-divider></el-divider>


				<!-- <el-scrollbar style="height: 200px;">
					<div style="display: inline-block;width: 20%;white-space: nowrap;"
						v-for="(item,index) in list[21].articleModels" :key="index">
						<el-image style="width: 100%;vertical-align: top;" :src="require('../assets/views/pic13.png')">
						</el-image>
					</div>
				</el-scrollbar> -->
				<!-- <el-carousel arrow="never"
					indicator-position="none" v-if="carouselArr.length">
					<el-carousel-item v-for="(items, indexs) in carouselArr" :key="indexs">
						<span v-for="(item, index) in items" :key="index">
							<router-link style="text-decoration: none;color: inherit;margin-left: 15px;"
								:to="'/'+list[21].catId+'?mid='+item.id">
								<el-image style="width: 15%;vertical-align: top;" :src="item.imageUrl">
									<div slot="error" class="image-slot">
							
									</div>
								</el-image>
							</router-link>
						</span>
					</el-carousel-item>
				</el-carousel> -->
				<div class="lu">
					<nav>
						<ul style="width: 10000%;" class="lusj">
							<li v-for="(item,index) in list[21].articleModels" :key="index"
								style="float: left;margin-left: 10px;">
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/'+list[21].catId+'/list?mid='+item.id">
									<el-image style="width: 300px;height: 191px;" :src="item.imageUrl">
										<div slot="error" class="image-slot">

										</div>
									</el-image>
								</router-link>
							</li>

						</ul>
					</nav>
				</div>
				<!-- <el-row :gutter="20">

					<el-col :span="4" v-for="(item,index) in list[21].articleModels" :key="index">
						<router-link style="text-decoration: none;color:  inherit;"
							:to="'/'+list[21].catId+'/list?mid='+item.id">
							<el-image style="width: 100%;vertical-align: top;" :src="item.imageUrl">
								<div slot="error" class="image-slot">

								</div>
							</el-image>
						</router-link>
					</el-col>

				</el-row> -->
			</div>

			<div style="margin-top: 20px;height: 300px;" class="famd">
				<el-row :gutter="60">
					<el-col :span="12" style="margin-bottom: 10px;">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[93].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[93].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image
											style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="fhz">
										<div>
											<el-row  class="fhzsj">
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[93].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[93].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom: 10px;">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[94].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[94].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image
											style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="crlsh">
										<div>
											<el-row class="crlshsj">
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[94].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[94].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom: 10px;">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[96].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[96].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image
											style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="ls">
										<div>
											<el-row class="lssj">
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[96].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[96].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col>
					<!-- <el-col :span="8">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[95].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[95].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image style="height: 31px;vertical-align: top;margin-left: 10px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="jsz">
										<div>
											<el-row>
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[95].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[95].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col> -->
					<el-col :span="12" style="margin-bottom: 10px;">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[98].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[98].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image
											style="height: 31px;vertical-align: top;margin-left: 10px;width: 31px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="ybhy">
										<div>
											<el-row class="ybhysj">
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[98].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;width: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[98].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>



			<!-- 	<div style="margin-top: 20px;height: 300px;" class="famd">
				<el-row :gutter="20">
					
					<el-col :span="8">
						<div class="bosem">
							<div style="margin-top: 5px;">
								<h2
									style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
									{{list[97].catName}}
								</h2>
								<div style="cursor: pointer;float: right;">
									<router-link style="text-decoration: none;color:  inherit;"
										:to="'/'+list[97].catId">
										<span style="font-size: 24px;color: rgb(39,103,176);">更多</span>
										<el-image style="height: 31px;vertical-align: top;margin-left: 10px;"
											:src="require('../assets/views/icon_more_blue.png')">
										</el-image>
									</router-link>
								</div>
								<el-divider></el-divider>
							</div>
							<div>
								<el-card style="height: 240px;">
									<div style="height: 200px;overflow: hidden;" class="js">
										<div>
											<el-row>
												<el-col style="padding: 5px 0;" :span="24"
													v-for="(item,index) in list[97].articleModels" :key="index">
													<span
														style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
														<el-image style="height: 10px;margin-right: 10px;"
															:src="require('../assets/views/icon_choose.png')">
														</el-image>
														<span v-if="item.link">
															<a style="text-decoration: none;color: inherit;"
																target="_blank" :href="item.link">
																{{item.name}}
															</a>
														</span>
														<span v-else>
															<router-link style="text-decoration: none;color:  inherit;"
																:to="'/'+list[97].catId+'?mid='+item.id">
																{{item.name}}
															</router-link>
														</span>
													</span>
												</el-col>
											</el-row>
										</div>
									</div>
								</el-card>
							</div>
						</div>
					</el-col>
					
				</el-row>
			</div> -->



			<!-- <div style="margin-top: 40px;" class="famd">
				<div class="zswd">
					<el-image style="height: 35px;" :src="require('../assets/views/icon_building.png')">
					</el-image>
					<h2 style="display: inline-block;vertical-align: top;color: #fff;margin-left: 10px;">
						理事及以上单位<span style="font-size: 18px;">（{{list[22].articleModels.length}}家）</span></h2>
					<div style="cursor: pointer;float: right;">
						<router-link style="text-decoration: none;color:  inherit;" :to="'/'+list[22].catId">
							<span style="font-size: 24px;color: #fff;">更多</span>
							<el-image style="height: 31px;vertical-align: top;margin-left: 10px;"
								:src="require('../assets/views/icon_more.png')">
							</el-image>
						</router-link>
					</div>

					<div class="zswdto" style="margin-top: 20px;">
						<el-card style="overflow-y: auto;height: 140px;">
							<div style="color: #fff;">
								<div style="padding: 5px;" class="topgd">





									<el-row :gutter="20">
										<el-col style="margin-top: 5px;" :span="6"
											v-for="(item,index) in list[22].articleModels" :key="index">
											<span
												style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;">
												<el-image style="height: 10px;margin-right: 10px;"
													:src="require('../assets/views/icon_choose.png')">
												</el-image>
												<span v-if="item.link">
													<a style="text-decoration: none;color: inherit;" target="_blank"
														:href="item.link">
														{{item.name}}
													</a>
												</span>
												<span v-else>
													<router-link style="text-decoration: none;color:  inherit;"
														:to="'/'+list[22].catId+'?mid='+item.id">
														{{item.name}}
													</router-link>
												</span>

											</span>
										</el-col>

									</el-row>
								</div>

							</div>
						</el-card>
					</div>
				</div>
			</div> -->

			<div style="margin-top: 20px;" class="famd">
				<el-image style="height: 35px;width: 35px;" :src="require('../assets/views/icon_link.png')">
				</el-image>
				<h2 style="display: inline-block;vertical-align: top;color: rgb(64,115,181);margin-left: 10px;">
					友情链接</h2>
				<el-divider></el-divider>
				<div
					style="color: rgb(50,134,218);text-align: center;margin: 15px 0;background-color: rgb(246, 250, 255);">
					<el-row>


						<el-col style="height: 40px;line-height: 40px;font-size: 14px;" :span="4"
							v-for="(item,index) in linkModels" :key="index">
							<a style="text-decoration: none;color: inherit;" target="_blank"
								:href="item.linkUrl">{{item.linkName}}</a>
						</el-col>

					</el-row>

				</div>
			</div>

		</div>


	</div>
</template>

<script>
	//导入我们写的组件
	// import Member from '../components/member.vue'
	//导入函数getMenuList
	import {
		getMenuList,
		login
	} from '../api/interface.js';
	import {
		mapState

	} from "vuex";
	export default {
		data() {
			return {
				gggdimg: '',
				ggglink: '',
				wsg: '100px',
				msg: [],
				linkModels: [],
				loading: false,
				input: "",
				posX: 0, // 浮动窗口的左边距
				posY: 0, // 浮动窗口的上边距
				speedX: 1, // 水平速度
				speedY: 1, // 垂直速度
				shopshow: true,
				list: [{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},
					{
						articleModels: [],
						modelList: [],
						parentId: ''
					},

				],
				activeName: 'a',
				activeNamefour: '标准预研',
				activeNamefourto: '标准预研',
				activeNamethere: '行业要闻',
				form: {
					name: '',
					password: '',
				},
				carouselArr: [],
				rules: {
					name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '请输入6位数以上密码',
							trigger: 'blur'
						}
					],
				},
				list_login: {},
				site_banner: "",



			}
		},
		computed: {
			...mapState(['isLogin', 'loginInfo'])
		},

		created() {
			var stl = document.styleSheets[0]
			//第一种 写法
			this.loading = true
			getMenuList({}).then(res => {
				this.gggdimg = Object.freeze(res.data.data.floating_img)
				this.gggdlink = Object.freeze(res.data.data.floating_link)
				this.msg = Object.freeze(res.data.data.showModels)
				this.list = Object.freeze(res.data.data.indexs); //vue 就不会对这个数据 进行响应式处理好
				this.linkModels = Object.freeze(res.data.data.linkModels);
				this.site_banner = Object.freeze(res.data.data.site_banner);
				let wd = this.list[21].articleModels.length * 310 - 1170 > 0 ? this.list[21].articleModels.length *
					310 - 1170 : 0;
				// let wdto = this.list[67].articleModels.length * 310 - 1170 > 0 ? this.list[67].articleModels
				// 	.length * 310 - 1170 : 0;
				// let hd = Math.ceil(this.list[22].articleModels.length / 4) * 30 > 0 ? Math.ceil(this.list[22]
				// 	.articleModels.length / 4) * 30 : 0;
				// stl.insertRule(
				// 	"@keyframes picmovefix {from {transform: translateY(90px);}to {transform: translateY(-" + hd +
				// 	"px);}}", 0);
				// stl.insertRule(
				// 	"@keyframes picmoveto {from {transform: translateX(0);}to {transform: translateX(-" +
				// 	wdto + "px);}}", 0);
				stl.insertRule("@keyframes picmove {from {transform: translateX(0);}to {transform: translateX(-" +
					wd + "px);}}", 0);


				let hd1 = this.list[93].articleModels.length * 35 > 0 ? this.list[93].articleModels.length *
					35 : 0;
				let hd2 = this.list[94].articleModels.length * 35 > 0 ? this.list[94].articleModels.length *
					35 : 0;
				// let hd3 = this.list[95].articleModels.length * 35 > 0 ? this.list[95].articleModels.length *
				// 	35 : 0;
				let hd4 = this.list[96].articleModels.length * 35 > 0 ? this.list[96].articleModels.length *
					35 : 0;
				// let hd5 = this.list[97].articleModels.length * 35 > 0 ? this.list[97].articleModels.length *
				// 	35 : 0;
				let hd6 = this.list[98].articleModels.length * 35 > 0 ? this.list[98].articleModels.length *
					35 : 0;
				stl.insertRule(
					"@keyframes picmovefhz {from {transform: translateY(200px);}to {transform: translateY(-" +
					hd1 +
					"px);}}", 0);
				stl.insertRule(
					"@keyframes picmovecrlsh {from {transform: translateY(200px);}to {transform: translateY(-" +
					hd2 +
					"px);}}", 0);
				// stl.insertRule(
				// 	"@keyframes picmovejsz {from {transform: translateY(200px);}to {transform: translateY(-" +
				// 	hd3 +
				// 	"px);}}", 0);
				stl.insertRule(
					"@keyframes picmovels {from {transform: translateY(200px);}to {transform: translateY(-" +
					hd4 +
					"px);}}", 0);

				// stl.insertRule(
				// 	"@keyframes picmovejs {from {transform: translateY(200px);}to {transform: translateY(-" +
				// 	hd5 +
				// 	"px);}}", 0);
				stl.insertRule(
					"@keyframes picmoveybhy {from {transform: translateY(200px);}to {transform: translateY(-" +
					hd6 +
					"px);}}", 0);
				// object.style.animationDuration="3s"
				// stl.insertRule(
				// 	".ls .el-row {animation-duration:10s;}", 0);
				this.$nextTick(function() {
					//调用需要执行的方法
					// var aaa = document.getElementsByClassName('lssj')
					// console.log(aaa[0])
					var lssj = (this.list[96].articleModels.length + 7) * 0.6
					document.getElementsByClassName('lssj')[0].style.animationDuration = lssj + 's';

					var ybhysj = (this.list[98].articleModels.length + 7) * 0.6
					document.getElementsByClassName('ybhysj')[0].style.animationDuration = ybhysj + 's';

					var crlshsj = (this.list[94].articleModels.length + 7) * 0.6
					document.getElementsByClassName('crlshsj')[0].style.animationDuration = crlshsj + 's';
					
					var fhzsj = (this.list[93].articleModels.length + 7) * 0.6
					document.getElementsByClassName('fhzsj')[0].style.animationDuration = fhzsj + 's';

					var lusj = this.list[21].articleModels.length * 2
					document.getElementsByClassName('lusj')[0].style.animationDuration = lusj + 's';

					// var lutosj = this.list[67].articleModels.length * 2
					// document.getElementsByClassName('lutosj')[0].style.animationDuration = lutosj + 's';
				})







				this.activeNamefour = this.list[53].modelList[0].catName
				this.activeNamefourto = this.list[59].modelList[0].catName
				this.loading = false



				// console.log(this.carouselArr)

			})
			//第二种
			// const result = await getMenuList({});
			//这下面的代码 相当于上面的 then  就是下面的代码 需要请求结束后才会执行
		},
		mounted() {
			this.startFloating(); // 开始浮动
		},
		
		methods: {
			startFloating() {
				setInterval(() => {
					const screenWidth = window.innerWidth; // 页面宽度
					const screenHeight = window.innerHeight; // 页面高度

					this.posX = (this.posX + this.speedX) % screenWidth;
					this.posY = (this.posY + this.speedY) % screenHeight;
				}, 1); // 每10毫秒更新一次位置
			},
			div_hide(){
				this.shopshow = false;
			},
			tcdl() {
				this.$message({
					message: '退出成功！',
					type: 'success',
					duration: 1000,
					onClose: () => {
						this.$store.commit('setIsLogin', false);
						window.sessionStorage.removeItem('userId');
					}
				})

			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(valid)
						login({
							email: this.form.name,
							password: this.form.password
						}).then(res => {
							// console.log(res)
							if (res.data.success) {
								this.$message({
									message: '登录成功！',
									type: 'success',
									duration: 1000,
									onClose: () => {
										this.$store.commit('setIsLogin', true);
										this.$store.commit('setLoginInfo', JSON.stringify(res
											.data.data));
										window.sessionStorage.setItem('userId', JSON.stringify(
											res.data.data));
										this.$router.push('../membersto')
									}
								})

								// this.logins = true
								// this.list_login = res.data.data
							} else {
								this.$message.error(res.data.message);
								window.sessionStorage.removeItem('userId');
							}
						}, err => {
							console.log(err, 'ooo')
						})


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			//关闭广告
			closegg() {
				this.gggdimg = ''
			},
			//重置账号密码
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = new Date(parseInt(shijianchuo) * 1000);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();

				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},

			//重置账号密码
			reset() {
				this.form = {
					name: '',
					password: ''
				}
			},
			search() {
				if (this.input) {
					this.$router.push('/search?name=' + this.input)
				} else {
					this.$message.error('请输入搜索内容');
				}
			}
		}
	}
	
	
</script>

<style>
	.white {
		color: #fff;
	}

	.home-header {
		/* background-image: url(../assets/views/banner-5.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		-moz-background-size: 100% 100%; */
		height: 280px;
	}

	.clearfix .el-card__header {
		background-image: url(../assets/views/note_bg-23.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-color: rgb(37, 121, 213);
		color: #fff;
	}

	.xhdt {
		background-color: rgb(56, 131, 215);
		height: 390px;
		background-image: url(../assets/views/news_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.clearone .el-card__header {
		background-image: url(../assets/views/vip_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-color: rgb(242, 135, 57);
		color: #fff;
	}

	.clearone .el-card__body {
		height: 100%;
		background-color: rgb(249, 249, 250);
	}

	.standard {
		background-color: rgb(250, 251, 251);
		width: 100%;
		padding-top: 20px;
	}

	.standard .el-tabs__header {
		margin: 0 auto;
	}

	.standard .el-tabs__nav-wrap::after {
		z-index: -1;
	}

	.standard .el-tabs__item,
	.bosem .el-tabs__item {
		color: rgb(126, 138, 150);
		font-weight: 600;
		font-size: 24px;
	}

	.cardto .el-tabs__item {
		padding: 0;
	}



	.el-tabs__item.is-active {
		color: rgb(39, 103, 176);
	}

	.standard .el-tabs__active-bar,
	.bosem .el-tabs__active-bar {
		background-color: rgb(39, 103, 176);
		/* cursor: pointer; */
	}

	.cardto .el-tabs__active-bar {
		top: 50px;
	}

	.cardto .el-divider--horizontal {
		margin-top: 5px;
	}

	.cardto .el-tabs__item {
		height: 60px;
	}

	.famd .el-divider--horizontal {
		margin: 10px 0;
	}

	.famd .el-divider {
		height: 2px;
		background-color: rgb(64, 115, 181);
	}

	.imgtext {
		background-color: rgb(57, 145, 237);
		height: 80px;
		color: #fff;
		font-size: 20px;
		padding: 10px;
		text-align: center;
	}

	.dc {
		background-color: rgb(15, 123, 217);
		height: 60px;
		line-height: 60px;
		position: absolute;
		bottom: 3px;
		width: 100%;
		opacity: 0.9;
		color: #fff;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-indent: 5px;
	}

	.bosem .el-tabs__nav-wrap::after {
		z-index: 999;
		background-color: rgb(50, 134, 218);
	}

	.bosem .el-tabs__item {
		height: 50px;
	}

	.bosem .el-tabs__header {
		width: 100%;
		margin: 0 0 15px;
	}

	.bosem .el-divider--horizontal {
		height: 2px;
		margin: 3px 0 20px 0;
		background-color: rgb(50, 134, 218);
	}

	.bosem .el-card__body {
		background-color: rgb(248, 248, 249);
	}

	.zswd {
		background-color: rgb(27, 91, 172);
		padding: 40px 40px;
	}

	.zswdto .el-card {
		background-color: rgb(24, 82, 156);
		border: none;
	}

	::-webkit-scrollbar {
		width: 0px;
		/*滚动条宽度*/
		height: 7px;
		/*滚动条高度*/
		background-color: rgb(24, 82, 156);
	}

	.famd ::-webkit-scrollbar {
		width: 5px;
		/*滚动条宽度*/
		height: 7px;
		/*滚动条高度*/
		background-color: rgb(24, 82, 156);
	}

	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #fff;
		/*滚动条的背景颜色*/
	}

	.topgd {
		height: 90px;
		overflow: hidden;
	}

	.topgd .el-row {
		height: 90px;
		animation: picmovefix 5s linear infinite forwards;
		/* animation-duration: 2s; */
	}



	.fhz .el-row {
		animation: picmovefhz 10s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	.crlsh .el-row {
		animation: picmovecrlsh 10s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	.jsz .el-row {
		animation: picmovejsz 10s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	.ls .el-row {
		animation: picmovels 50s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	.js .el-row {
		animation: picmovejs 10s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	.ybhy .el-row {
		animation: picmoveybhy 320s linear infinite forwards;
		/* animation-duration: 2s; */
	}

	/* 	@keyframes picmoveto {
		from {
			transform: translateY(0);
		}
	
		to {
			transform: translateY(-100%);
		}
	} */

	.el-main {
		overflow: hidden;
	}


	.lu ul,
	.luto ul {
		list-style: none;
	}

	.lu nav,
	.luto nav {
		margin: 0 auto;
		overflow: hidden;
	}

	.lu ul {
		animation: picmove linear infinite forwards alternate;
	}

	.luto ul {
		animation: picmoveto linear infinite forwards alternate;
	}

	/* 
	@keyframes picmove {
		from {
			transform: translate(0);
		}

		to {
			transform: translate(-1800px);
		}
	} */

	.topgd .el-row:hover {
		animation-play-state: paused;
	}

	.lu ul:hover,
	.luto ul:hover,
	.fhz .el-row:hover,
	.crlsh .el-row:hover,
	.jsz .el-row:hover,
	.ls .el-row:hover,
	.js .el-row:hover,
	.ybhy .el-row:hover,
	#dhxg:hover {
		animation-play-state: paused;
	}
	.floating-window{position: fixed;width: 150px;height: 150px;cursor: move;background-color: red;z-index: 9999;}
	#dhxg {
		animation: picmovedhxg 15s linear infinite forwards alternate;
	}

	@keyframes picmovedhxg {
		0% {
			transform: translate(0);
		}

		10% {
			transform: translate(167px, 60px);
		}

		20% {
			transform: translate(334px, 0);
		}

		30% {
			transform: translate(501px, 60px);
		}

		40% {
			transform: translate(668px, 0);
		}

		50% {
			transform: translate(835px, 60px);
		}

		60% {
			transform: translate(1002px, 0);
		}

		70% {
			transform: translate(1169px, 60px);
		}

		80% {
			transform: translate(1336px, 0);
		}

		90% {
			transform: translate(1503px, 60px);
		}

		100% {
			transform: translate(1670px, 0);
		}

	}
</style>
