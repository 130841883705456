<template>
	<div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>首页</el-breadcrumb-item>
			<el-breadcrumb-item>会员中心</el-breadcrumb-item>
			<el-breadcrumb-item>会员注册</el-breadcrumb-item>
		</el-breadcrumb>

		<el-card style="margin-top: 20px;" shadow="never">
			<div slot="header">
				<h3 style="margin-left: 20px;">会员注册</h3>
			</div>
			<div style="width: 55%;margin: 0 auto;margin-bottom: 70px;">
				<el-form style="margin-top: 30px;" :rules="rules" ref="form" :model="form" label-width="120px">
					<el-form-item label="用户名:" prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="登录密码:" prop="password">
						<el-input placeholder="请输入6位数以上密码" show-password v-model="form.password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码:" prop="passwordto">
						<el-input placeholder="再次输入密码" show-password v-model="form.passwordto"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button style="width: 45%;" @click="resetForm('form')">重置</el-button>
						<el-button type="primary" style="width: 45%;" @click="onSubmit('form')">立即注册</el-button>
					</el-form-item>
				</el-form>
				<div style="font-size: 14px;margin-left: 150px;">
					<el-image style="height: 16px;vertical-align: top;"
						:src="require('../../assets/views/icon_remain.png')">
					</el-image>
					已有会员账号？立即<router-link to="/members/login"
						style="text-decoration: underline;cursor: pointer;color: rgb(48,118,211);">登录</router-link>
				</div>
			</div>

		</el-card>
	</div>
</template>

<script>
	import {
		register
	} from '../../api/interface.js'
	export default {
		data() {
			return {
				path: "",
				form: {
					name: '',
					password: '',
					passwordto: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '请输入6位数以上密码',
							trigger: 'blur'
						}
					],
					passwordto: [{
						required: true,
						message: '请再次输入密码',
						trigger: 'blur'
					}, ],
				},
			}
		},

		created() {
			this.onRouteChanged()
		},
		methods: {
			onSubmit(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(valid)
						if (this.form.password == this.form.passwordto) {
							register({
								email: this.form.name,
								password: this.form.password
							}).then(res => {
								if (res.data.success) {
									this.$message({
										message: '注册成功！',
										type: 'success',
										duration:1000,
										onClose: () => {
											this.$router.push({
												path: '/members/login',
											});
										}
									})
								} else {
									this.$message.error(res.data.message);
								}
							})
						} else {
							this.$message.error('密码不一致，请重新输入');
							this.form.passwordto = ''
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//重置账号密码
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			onRouteChanged() {
				let that = this
				that.path = that.$route.path;
			}

		}
	}
</script>

<style>
</style>
