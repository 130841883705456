<template>
	<div>
		<div v-if="!themesId" v-loading="loading">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<!-- <el-breadcrumb-item v-if="navName == ''">{{navName}}</el-breadcrumb-item>
				<el-breadcrumb-item v-if="$route.params.chId !== 'list'">{{chlidrenName}}</el-breadcrumb-item> -->
				<el-breadcrumb-item v-if="catname">{{catname}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div v-if="list.length">
				<el-card style="margin-top: 20px;" shadow="never">
					<div slot="header">
						<h3 style="margin-left: 20px;">{{chlidrenName}}</h3>
					</div>
					<div style="">
						<el-row v-for="(item,index) in list" style="padding: 10px 0;" :key="index">
							<el-col :span="20">
								<div style="display: flex; align-items: center;">
									<span @click="xq(item.id)"
										style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer;">
										<el-image style="height: 10px;margin-right: 10px;width: 10px;"
											:src="require('../assets/views/icon_list@2x-1.png')">
										</el-image>

										{{item.name}}
										
									</span>
									<template>
										<el-image v-if="item.isNew == 1"
											style="height: 11px;width: 24px;flex-shrink: 0;"
											:src="require('../assets/views/new.gif')">
										</el-image>
										<el-image v-if="item.isTop == 1"
											style="height: 15px;width: 28px;flex-shrink: 0;"
											:src="require('../assets/views/zhiding.png')">
										</el-image>
									</template>
								</div>
							</el-col>
							<el-col :span="4" style="text-align: right;">{{format(item.addTime)}}
							</el-col>
						</el-row>
						<div style="width: 80%;margin: 0 auto;">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:page-size="20" :current-page="pageNo" :hide-on-single-page="true"
								layout="total, prev, pager, next, jumper" :total="list_info.articlePage.totalRows">
							</el-pagination>
						</div>

					</div>


				</el-card>
			</div>
			<div v-else>
				<el-card style="margin-top: 20px;" shadow="never">
					<el-empty description="无数据"></el-empty>
				</el-card>
			</div>
		</div>
		<div v-else v-loading="loading">
			<el-card style="margin-top: 20px;" shadow="never">
				<div v-if="list_info.moduleModels[0].themesId != 'article2.dwt'" slot="header">
					<h3 style="margin-left: 20px;"><i style="cursor: pointer;" @click="ht"
							class="el-icon-arrow-left"></i></h3>
				</div>
				<viv>
					<h3 style="text-align: center;margin-bottom: 10px;">{{ catname }}</h3>
					<div v-if="botno" style="text-align: center;margin-bottom: 60px;font-size: 14px;">
						发布时间：{{addTime}}<span style="margin-left: 10px;">点击数：{{click}}</span></div>

					<div v-if="res != ''">
						<div v-html="res" class="content">

						</div>
					</div>
					<div v-else>
						<el-empty description="无数据"></el-empty>
					</div>
				</viv>

			</el-card>
		</div>


	</div>
</template>

<script>
	// import {
	// 	mapState
	// } from 'vuex'
	import {
		getArticleCategoryList,
		getArticleDetail
	} from '../api/interface.js'
	export default {
		data() {
			return {
				addTime: '',
				click: '',
				pageNo: 1,
				pageSize: 20,
				loading: false,
				themesId: false,
				navName: "", //当前所在的顶级菜单名
				chlidrenName: "", //二级菜单name
				list_bq: '',
				list: [],
				res: "",
				list_info: {
					moduleModels: [{}]
				},
				catname: ''
			}
		},
		created() {
			if (this.$route.query.mid) {
				this.xq(this.$route.query.mid)
			} else {
				this.getMenuList()
			}
			this.pageNo = 1
			this.botno = false
			// this.init();
			// this.childrenInit()

		},
		// computed: {
		// 	...mapState(['navMenus', 'childrenNavMenus'])
		// },
		watch: {
			// '$route'(){
			// 	this.$router.push({
			// 		query: {}
			// 	});
			// 	this.res = ""
			// 	this.themesId = false
			// 	this.catname = ''
			// 	this.getMenuList()
			// },
			'$route.params.id'() {
				this.pageNo = 1
				// this.res = {}
				this.themesId = false
				this.botno = false
				// this.init()
				// this.childrenInit();
				this.getMenuList()
			},
			'$route.query.mid'() {
				this.botno = false
				
				this.res = ""
				this.themesId = false
				this.catname = ''
				this.getMenuList()
				
		// 		this.botno = false
		// 		if (this.$route.query.mid) {

		// 			this.themesId = false
		
		// 			this.getMenuList()
			
		// 		}
			},
			'$route.params.chId'() {
				this.botno = false
				if (!this.$route.query.mid) {
					this.pageNo = 1
					this.res = ""
					this.themesId = false
					// this.init();
					this.getMenuList()
					// this.childrenInit();
				}
			}
		},
		methods: {
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = new Date(parseInt(shijianchuo) * 1000);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();

				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},
			getMenuList() {
				this.loading = true
				if (this.$route.params.chId != 'list') {

					getArticleCategoryList({
						catId: this.$route.params.chId,
						pageNo: this.pageNo,
						pageSize: this.pageSize
					}).then(res => {
						this.catname = res.data.data.moduleModels[0].catName
						if (res.data.data.moduleModels[0].themesId == 'article2.dwt') {
							this.themesId = true
							this.res = res.data.data.moduleModels[0].content
							// console.log(this.res)
						} else {
							this.themesId = false
						}

						this.list = res.data.data.articlePage.rows
						this.list_info = res.data.data
						if (this.$route.query.mid) {
							this.xq(this.$route.query.mid)
						} else {
							this.loading = false
						}
					})

				} else {
					getArticleCategoryList({
						catId: this.$route.params.id,
						pageNo: this.pageNo,
						pageSize: this.pageSize
					}).then(res => {
						this.catname = res.data.data.moduleModels[0].catName
						// console.log(res.data.data.moduleModels[0].catName)
						this.list = res.data.data.articlePage.rows
						this.list_info = res.data.data
						if (this.$route.query.mid) {
							this.xq(this.$route.query.mid)
						} else {
							this.loading = false
						}
					})
				}

			},
			// init() {
			// 	if (this.navMenus.length) {
			// 		const navId = this.$route.params.id;
			// 		const navInfo = this.navMenus.find(t => t.guide === navId)
			// 		this.navName = navInfo.navName;
			// 	}
			// },
			// childrenInit() {
			// 	if (this.childrenNavMenus.length) {
			// 		const chId = this.$route.params.chId;
			// 		const chInfo = this.childrenNavMenus.find(t => t.catId === chId);
			// 		this.chlidrenName = chInfo && chInfo.catName || "";
			// 		// console.log(this.chlidrenName)
			// 	}
			// },
			xq(e) {
				this.loading = true
				getArticleDetail({
					id: e
				}).then(res => {
					// console.log(res)
					if (res.data.data.articleModel.link) {
						window.open(res.data.data.articleModel.link);
					} else {
						this.addTime = res.data.data.articleModel.addTime
						this.click = res.data.data.articleModel.click
						this.catname = res.data.data.articleModel.name
						this.res = res.data.data.articleModel.content
						this.botno = true
						this.themesId = true
						this.$router.push(this.$route.path+'?mid='+res.data.data.articleModel.id);
					}
					this.loading = false
				})
			},
			ht() {
				this.$router.push({
					query: {}
				});
				this.res = ""
				this.themesId = false
				this.getMenuList()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getMenuList()
				// console.log(`当前页: ${val}`);
			},


		}
	}
</script>

<style>
	.content img {
		max-width: 100%;
		height: auto;
	}
</style>
