<template>
	<div>
		<div style="width: 1170px;margin: 0 auto;" v-loading="loading">
			<div v-if="res">
				<el-card style="margin-top: 20px;" shadow="never">
					<div slot="header">
						<h3 style="margin-left: 20px;"><i style="cursor: pointer;" @click="ht"
								class="el-icon-arrow-left"></i></h3>
					</div>

					<viv>
						<h3 style="text-align: center;margin-bottom: 10px;">{{ catname }}</h3>
						<div style="text-align: center;margin-bottom: 60px;font-size: 14px;">发布时间：{{addTime}}<span
								style="margin-left: 10px;">点击数：{{click}}</span></div>

						<div v-html="res" class="content" style="margin: 0 auto;width: 810px;">

						</div>

					</viv>
					<!-- <div v-html="res" class="content">

					</div> -->
				</el-card>
			</div>


			<div v-else>
				<div v-if="list.length">
					<el-card style="margin-top: 20px;" shadow="never">
						<div style="">
							<el-row v-for="(item,index) in list" style="padding: 10px 0;" :key="index">
								<el-col :span="20">
									<div style="display: flex; align-items: center;">
										<span @click="xq(item.id)"
											style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer;">
											<el-image style="height: 10px;margin-right: 10px;width: 10px;"
												:src="require('../assets/views/icon_list@2x-1.png')">
											</el-image>

											{{item.name}}
											
										</span>
										<template>
											<el-image v-if="item.isNew == 1"
												style="height: 11px;width: 24px;flex-shrink: 0;"
												:src="require('../assets/views/new.gif')">
											</el-image>
											<el-image v-if="item.isTop == 1"
												style="height: 15px;width: 28px;flex-shrink: 0;"
												:src="require('../assets/views/zhiding.png')">
											</el-image>
										</template>
									</div>
								</el-col>
								<el-col :span="4" style="text-align: right;">{{format(item.addTime)}}
								</el-col>
							</el-row>
							<div style="width: 80%;margin: 0 auto;">
								<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
									:page-size="20" :current-page="pageNo" :hide-on-single-page="true"
									layout="total, prev, pager, next, jumper" :total="list_info.articlePage.totalRows">
								</el-pagination>
							</div>

						</div>


					</el-card>
				</div>
				<div v-else>
					<el-card style="margin-top: 20px;" shadow="never">
						<el-empty description="无数据"></el-empty>
					</el-card>
				</div>
			</div>

		</div>



	</div>
</template>

<script>
	import {
		getArticleDetail,
		findArticlePage,
	} from '../api/interface.js'
	export default {
		data() {
			return {
				addTime: '',
				click: '',
				catname: '',
				pageNo: 1,
				pageSize: 20,
				loading: false,
				themesId: false,
				navName: "", //当前所在的顶级菜单名
				chlidrenName: "", //二级菜单name
				list_bq: '',
				list: [],
				res: "",
				list_info: {
					moduleModels: [{}]
				}
			}
		},
		created() {
			if (this.$route.query.name) {
				this.getMenuList()
				// this.xq(this.$route.query.name)
			}
			this.pageNo = 1



		},

		methods: {
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = new Date(parseInt(shijianchuo) * 1000);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();

				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},
			getMenuList() {
				this.loading = true
				findArticlePage({
					name: this.$route.query.name,
					pageNo: this.pageNo,
					pageSize: this.pageSize
				}).then(res => {
					this.list = res.data.data.articlePage.rows
					// console.log(this.list)
					this.list_info = res.data.data
					this.loading = false
				})

			},

			xq(e) {
				this.loading = true
				getArticleDetail({
					id: e
				}).then(res => {
					this.addTime = res.data.data.articleModel.addTime
					this.click = res.data.data.articleModel.click
					this.catname = res.data.data.articleModel.name
					this.res = res.data.data.articleModel.content
					this.loading = false
				})
			},
			ht() {

				this.res = ''
				// this.getMenuList()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getMenuList()
				// console.log(`当前页: ${val}`);
			},


		}
	}
</script>

<style>
	.content img {
		max-width: 100%;
		height: auto;
	}
</style>
