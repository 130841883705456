<template>
	<div>
		<div v-if="type==1">
			<el-card shadow="never">
				<div slot="header">
					<h3 style="margin-left: 20px;">我的申请</h3>
				</div>
				<div style="width: 80%;margin: 0 auto;">
					<div style="line-height: 2em;">
						我们热忱欢迎您加入广东省水利水电行业协会，我们将热情为您提供相关服务。<br />
						单位提交入会申请同时，请在附件一栏上传加盖公章的单位入会申请表、社会统一信用代码证、会员代表身份证和近2年水利项目清单的压缩包。<br />
						请申请加入我协会的单位，点击下方的“单位申请入会”进入。<br />
						广东省水利水电行业协会期待着您的加入！<br />
					</div>

					<div class="memship">
						<el-descriptions>
							<el-descriptions-item :span="3" label="联系人">方禧，林秋颖，杨静敏，罗杰，黄金泉</el-descriptions-item>
							<el-descriptions-item :span="3" label="联系电话">020-87566154，87567864，38356479，020-38356117
							</el-descriptions-item>
							<el-descriptions-item :span="3" label="电子邮箱">gdslhyxh@163.com</el-descriptions-item>

							<el-descriptions-item :span="3" label="帐 号">44054001040004361</el-descriptions-item>
							<el-descriptions-item :span="3" label="开 户 行">广东省水利水电行业协会</el-descriptions-item>
							<el-descriptions-item :span="3" label="帐 号">农业银行广州市东城支行营业部地址: 广州市天河区天寿路116号广东水利大厦
							</el-descriptions-item>
						</el-descriptions>
					</div>
					<div style="margin-top: 10px;">
						<router-link style="text-decoration: none;color:  inherit;" :to="'/membersto/membership'">
							<el-button style="margin: 10px auto;" @click="type=2" type="primary">单位申请入会 ></el-button>
						</router-link>
					</div>
				</div>

			</el-card>
		</div>
		<div v-else-if="type==2" class="famd">
			<el-card shadow="never">
				<div slot="header">
					<h3 style="margin-left: 20px;">我的申请</h3>
				</div>
				<el-steps :active="0" align-center>
					<el-step title="阅读入会章程"></el-step>
					<el-step title="入会申请表"></el-step>
					<el-step title="完成申请"></el-step>
				</el-steps>
				<div style="height: 600px;overflow:auto;margin: 20px auto;line-height: 2em;">
					<div style="width: 80%;margin: 20px auto;">
						<h3>广东省水利水电行业协会章程</h3>
						<h4><span class="jiacu">第一章</span> 总 则</h4>
						<p><span class="jiacu">第一条</span> 本会的中文名称：广东省水利水电行业协会（以下简称本会）。英文名称：Guangdong Water Conservancy
							and Hydropower
							Association，英文缩写为GDWHA。</p>
						<p><span class="jiacu">第二条</span> 本会的性质：是由广东省水利水电行业的企业和事业单位、社会团体及其他相关组织自愿组成的非营利性社会组织。。</p>
						<p><span class="jiacu">第三条</span> 本会的办会方向和宗旨。</p>
						<p>（一）本会的办会方向：坚持中国共产党的全面领导，以毛泽东思想、邓小平理论、“三个代表”重要思想、科学发展观、习近平新时代中国特色社会主义思想为指导；</p>
						<p>（二）本会的宗旨：遵守国家的法律、法规和政策，践行社会主义核心价值观，遵守社会道德风尚，贯彻执行国家的水利水电工作方针。维护市场秩序与公平竞争，沟通会员与政府、社会之间关系，加强行业内协调管理、指导和监督的作用。坚持“服务、研究、交流、提高”的方针，为本行业会员提供服务，维护本行业会员单位的合法权益和共同经济利益，推动全省水利水电事业可持续发展。
						</p>
						<p><span class="jiacu">第四条</span> 本会的登记管理机关为广东省民政厅。本会接受登记管理机关的监督管理和广东省人民政府相关职能部门的业务指导。</p>
						<p></p>
						<p><span class="jiacu">第五条</span>本会的活动地域为广东省。</p>
						<p><span class="jiacu">第六条</span>本会设在广州市。</p>
						<h4><span class="jiacu">第二章</span>业务范围</h4>
						<p><span class="jiacu">第七条</span>本会的业务范围：</p>
						<p>（一）组织会员学习相关法律、法规和国家政策；</p>
						<p>（二）经相关部门委托授权，开展技术研发、技术改造、技术推广和技术咨询。开展行业调查研究，参与和接受与本行业发展、改革以及利益有关的政府决策活动，提出有关经济政策和立法方面论证、咨询和建议；
						</p>
						<p>（三）开展行业自律，制定行规行约，参与水利水电方面的国家、地方和行业标准化的制修订，参与行业职业技能鉴定的质量认证，不断提高行业素质和职业道德水准；</p>
						<p>（四）协助政府加强行业法制建设，促进公平、公开、公正的市场秩序的建立，接受委托参与协调行业之间的经济、法律关系，调解会员之间纠纷，为会员提供法律咨询服务；</p>
						<p>（五）开展科技创新、管理创新研究以及科技成果推荐、推广应用工作；经有关部门授权或审核备案，组织行业创新、评优、评奖，提高行业质量管理水平；</p>
						<p>（六）贯彻国家有关水利水电行业发展的政策、法规，研究贯彻过程中出现的问题，向政府有关部门反映行业的正当愿望和合理要求，维护行业的合法权益；</p>
						<p>（七）组织对水利水电领域内的经济理论与实务进行研究，举办各种类型的学术研讨会会、报告会，促进水利水电理论水平的提高；</p>
						<p>（八）开展技术和技能人员的继续教育、职业培训和技能鉴定工作；</p>
						<p>（九）组织本行业开展各项与国内、外有关经济组织和团体的活动，包括组织考察、人才培训、举办展览、经贸洽谈等，促进会员发展对外贸易、经济技术、科学管理、学术研究等方面的合作与交流；
						</p>
						<p>（十）组织发展行业的公益事业，参与和开展有利于提高本行业社会地位的各种社会活动；</p>
						<p>（十一）编辑发行会刊、年鉴、资料等刊物；</p>
						<p>（十二）开展本行业协会宗旨允许的业务，承担政府有关部门和会员单位委托的其他工作。</p>
						<p>（十三）业务范围中属于法律法规规章规定须经批准的事项，依法经批准后方可开展。</p>
						<h4><span class="jiacu">第三章</span>会 员</h4>
						<p><span class="jiacu">第八条</span></p>

						<p><span
								class="jiacu">本会只设单位会员，本会的会员为水利水电行业的企业、事业单位和团体，以及科研、教学、规划、勘测、设计、施工、生产、管理等单位。单位会员则以法定代表人或由其委派的业务主管领导人作为会员代表。</span>
						</p>

						<p><span class="jiacu">第九条</span>申请加入本会的会员，必须具备下列条件：</p>
						<p>（一）承认本会的章程，愿意参加本会的有关活动，支持本会工作；</p>
						<p>（二）有加入本会的意愿；</p>
						<p>（三）在本行业领域内具有一定的影响；</p>
						<p>（四）应持有工商营业执照等相关证件；</p>
						<p>（五）优先承接本会委托的技术改造、推广项目。</p>
						<p><span class="jiacu">第十条</span>会员入会的程序是：</p>
						<p>（一）向本会提交入会申请书；</p>
						<p>（二）经理事会讨论通过；</p>
						<p>（三）由理事会或理事会授权秘书处发给会员证。</p>
						<p><span class="jiacu">第十一条</span>会员享有下列权利：</p>
						<p>（一）出席会员大会，参加本会活动、接受本会提供的服务；</p>
						<p>（二）选举权、被选举权和表决权；</p>
						<p>（三）获得本会服务的优先权；</p>
						<p>（四）对本会工作的提议案权、建议权和监督权；</p>
						<p>（五）入会自愿、退会自由。</p>
						<p><span class="jiacu">第十二条</span>会员履行下列义务：</p>
						<p>（一）遵守本会章程；</p>
						<p>（二）执行本会的决议；</p>
						<p>（三）维护本会及本行业的合法权益；</p>
						<p>（四）完成本会委托的工作；</p>
						<p>（五）向本会反映情况，提供有关资料；</p>
						<p>（六）接受我会指导，独立开展会员活动。</p>
						<p><span class="jiacu">第十三条</span>会员缴纳会费的标准：</p>
						<p>（一）会长单位每年缴纳会费30000元；</p>
						<p>（二）副会长、常务理事、监事长单位每年缴纳会费20000元；</p>
						<p>（三）理事、监事单位每年缴纳会费5000元；</p>
						<p>（四）一般会员单位每年缴纳会费2000元。</p>
						<p><span class="jiacu">第十四条</span>会员退会应书面通知本行业协会，并交回会员证。连续两个年度不交纳会费或不参加本行业协会活动的，视为自动退会。</p>
						<p><span class="jiacu">第十五条</span>会员如不遵守本行业协会章程，将由本会提出批评、教育；如有严重违反本章程的行为，经会员大会表决通过，予以除名。</p>
						<h4><span class="jiacu">第四章</span>组织机构和负责人的产生、罢免</h4>
						<p><span class="jiacu">第十六条</span>本会的最高权力机构是会员大会。会员大会由会员代表组成，其议事表决采取无记名投票的方式。</p>
						<p><span class="jiacu">第十七条</span>会员大会行使下列职权：</p>
						<p>（一）决定本会在法律、法规规定范围内的业务范围和工作职能；</p>
						<p>（二）选举或者罢免（选举采取无记名投票的方法进行）会长、副会长、秘书长（选任制）、常务理事、理事、监事长、监事；</p>
						<p>（三）审议理事会、监事会（监事）的年度工作报告、年度财务工作方案、年度财务预决算方案；</p>
						<p>（四）审议理事会对会员除名的提议；</p>
						<p>（五）对本会变更、解散和清算等事项作出决议；</p>
						<p>（六）改变或者撤销理事会不适当的决定；</p>
						<p>（七）制订或修改章程（含会费标准）、组织机构的选举办法；</p>
						<p>（八）决定终止事宜；</p>
						<p>（九）决定其他重大事宜。</p>
						<p><span
								class="jiacu">第十八条</span>会员大会每届4年。因特殊情况需提前或延期换届的，须由理事会表决通过，报经社团登记管理机关批准同意。但延期换届最长不超过一年。会员大会每两年至少召开一次会议，理事会认为有必要或者五分之一以上的会员提议，可以召开临时会员大会。
						</p>
						<p><span
								class="jiacu">第十九条</span>会员大会必须有全体会员的2/3以上出席；其决议须经全体会员的过半数以上通过。会员大会应当对所议事项的决定作会议纪要，并向会员公告。
						</p>
						<p><span
								class="jiacu">第二十条</span>本会设理事会。理事会为会员大会的常设机构，在会员大会闭会期间，依照会员大会的决议和本会章程的规定履行职责。理事人数为会员数的1/3。
						</p>
						<p><span class="jiacu">第二十一条</span>理事会的职权是：</p>
						<p>（一）筹备和召集会员大会；</p>
						<p>（二）执行会员大会的决议，并向会员大会报告工作；</p>
						<p>（三）决定本会具体的工作业务；</p>
						<p>（四）拟定本会的年度财务预算方案、决算、变更、解散和清算等事项的方案；</p>
						<p>（五）制定本会增加或者减少注册资金的方案；</p>
						<p>（六）决定本会各内部机构的设置，并领导本会内部各机构开展工作；</p>
						<p>（七）决定新申请人的入会和对会员的处分，提议对会员的除名；</p>
						<p>（八）聘任或者解聘聘任制秘书长，决定协会分支机构主要负责人；根据秘书长提名，聘任或者解聘副秘书长和本会办事机构主要负责人，决定其报酬事项；选举和罢免常务理事；</p>
						<p>（九）制定协会内部管理制度；</p>
						<p>（十）协会章程规定的其他事项。</p>

						<p><span
								class="jiacu">第二十二条</span>理事会每半年召开一次会议（情况特殊的，也可采用通讯形式召开）。理事会须有过半数的理事出席方能召开，其决议须经全体理事过半数表决通过方能生效。理事会应当对决议形成会议纪要，并向全体理事公告。理事会会议由会长召集和主持，也可由会长委托副会长或秘书长召集和主持。
						</p>
						<p><span
								class="jiacu">第二十三条</span>本会根据需要设立常务理事会，常务理事会对理事会负责。常务理事会由会长、副会长、常务理事组成。常务理事人数为理事人数的1/3。常务理事会在理事会闭会期间，经理事会授权可以行使本章程第二十一条规定的第一、二、三、四、五、六、七、九项职权，聘任名誉会长、名誉副会长。常务理事会每半年召开一次会议；情况特殊的也可采用通讯形式召开。常务理事会须有过半数的常务理事出席方能召开，其决议必须有全体常务理事过半数以上通过。常务理事会应当对所议事项的决定作会议记录。
						</p>
						<p><span class="jiacu">第二十四条</span>本会设立分支机构、代表机构的规则、程序：</p>
						<p>（一）由本协会秘书处提出设立分支机构的具体方案；</p>
						<p>（二）将具体方案提交会长办公会议讨论通过；</p>
						<p>（三）将通过后的具体方案提交理事会或常务理事会审议批准。</p>
						<p><span
								class="jiacu">第二十五条</span>本会设立监事3名，由会员大会选举产生，监事任期与理事会任期相同，期满可以连任。会长、副会长、常务理事、理事、秘书长不得兼任监事。
						</p>
						<p><span class="jiacu">第二十六条</span>本会的会长、副会长、秘书长、监事必须具备下列条件：</p>
						<p>（一）坚持党的路线、方针、政策，遵守国家法律法规；</p>
						<p>（二）在本行业领域内有较大影响；</p>
						<p>（三）会长、副会长、秘书长最高任职年龄不得超过70周岁，秘书长为专职；</p>
						<p>（四）身体健康，能坚持正常工作；</p>
						<p>（五）未受到任何刑事处罚；</p>
						<p>（六）具有完全民事行为能力。</p>
						<p><span class="jiacu">第二十七条</span>本会的秘书长采用选任制，秘书长和会长不能在同一企业中产生。会长不得兼任秘书长。</p>
						<p><span class="jiacu">第二十八条</span> 本会设会长一人，副会长若干人。本会长为本会的法定代表人，本会法定代表人不得兼任其他社会团体的法定代表人。</p>
						<p><span class="jiacu">第二十九条</span>本会会长、副会长、选任制秘书长每届任期4年，连任不得超过两届。</p>
						<p><span class="jiacu">第三十条</span>本会会长行使下列职权：</p>
						<p>（一）召集和主持理事会（或常务理事会）会议；</p>
						<p>（二）检查会员大会、理事会（或常务理事会）决议的落实情况；</p>
						<p>（三）代表本会签署有关重要文件。</p>
						<p><span class="jiacu">第三十一条</span>本会副会长、秘书长在会长领导下开展工作，秘书长对理事会负责。秘书长为专职，行使下列职权：</p>
						<p>（一）主持办事机构开展日常工作，组织实施年度工作计划；</p>
						<p>（二）组织制定、实施年度工作计划和预算、决定；</p>
						<p>（三）协调各分支机构、代表机构、实体机构开展工作；</p>
						<p>（四）提名副秘书长以及各办事机构、分支机构、代表机构、实体机构主要负责人，交理事会或常务理事会决定；</p>
						<p>（五）提名办事机构、代表机构、实体机构专职工作人员的聘用或解聘，报会长办公会议批准；</p>
						<p>（六）处理其他日常事务；出席理事会、常务理事会会议。</p>
						<p><span class="jiacu">第三十二条</span>监事行使下列职权：</p>
						<p>（一）向会员大会报告年度工作；</p>
						<p>（二）监督会员大会和理事会的参会人员资格确认、程序和选举、罢免；监督理事会履行会员大会的决议；</p>
						<p>（三）检查本会财务和会计资料，向登记管理机关以及税务、会计主管部门反映情况；</p>
						<p>（四）监事列席理事会、常务理事会和会长办公会议，确认理事会、常务理事会、会长办公会议决议事项的合法有效性，有权向理事会、常务理事会、会长办公会议提出质询和建议；</p>
						<p>（五）监督理事会遵守法律和章程的情况。当会长、副会长、常务理事、理事和秘书长等开展业务活动损害本会利益时，要求其予以纠正，必要时向会员大会或政府相关部门报告。</p>
						<p>监事应当遵守有关法律法规和本会章程，接受会员大会领导，切实履行职责。</p>
						<h4><span class="jiacu">第五章</span>资产管理、使用原则</h4>
						<p><span class="jiacu">第三十三条</span>本会经费来源：</p>
						<p>（一）会费；</p>
						<p>（二）捐赠；</p>
						<p>（三）政府资助；</p>
						<p>（四）在核准的业务范围内开展活动或服务的收入；</p>
						<p>（五）利息；</p>
						<p>（六）其他合法收入。</p>
						<p><span
								class="jiacu">第三十四条</span>本会接受捐赠时，应当遵守法律法规，不得以任何形式进行摊派或变相摊派。捐赠人、资助人或单位、会员、监事有权向协会查询捐赠财产的使用、管理情况，并提出意见和建议。对于捐赠人、资助人或单位、会员、监事的查询，协会应及时如实答复。
						</p>
						<p><span class="jiacu">第三十五条</span>本会经费必须用于本章程规定的业务范围和事业的发展，财产以及其他收入受法律保护，任何单位、个人不得侵占、私分和挪用。</p>
						<p><span
								class="jiacu">第三十六条</span>本会会长、副会长、理事、监事、秘书长以及工作人员私分、侵占、挪用协会财产的，应当退还，并在会员大会上进行检讨；构成犯罪的，依法追究刑事责任。
						</p>
						<p><span
								class="jiacu">第三十七条</span>本会执行《民间非营利组织会计制度》，依法进行会计核算、建立健全内部会计监督制度，保证会计资料合法、真实、准确、完整。本会接受税务、会计主管部门依法实施的税务监督和会计监督。
						</p>
						<p><span
								class="jiacu">第三十八条</span>本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。
						</p>
						<p><span
								class="jiacu">第三十九条</span>本会的资产管理必须执行国家规定的财务管理制度，接受会员大会和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
						</p>
						<p><span class="jiacu">第四十条</span>本会进行年度报告、换届、变更法定代表人以及清算，必须接受登记管理机关组织的财务审计。</p>
						<p><span
								class="jiacu">第四十一条</span>本会按照《广东省行业协会条例》规定，于每年3月底前向登记管理机关报送上一年度活动报告、财务报告和本年度的活动安排。本会建立重大事项报告制度：本会召开大型学术报告会、研讨会、展览会，举办对外交流，与境外民间组织交往，开展业内评比、达标、表彰活动，接受境外及社会捐款等，在活动前向政府相关职能部门和登记管理机关报告并办理相关手续。
						</p>
						<p><span
								class="jiacu">第四十二条</span>本会专职工作人员实行全员聘任制，面向社会公开招聘，并订立劳动合同。其工资和保险、福利待遇，参照国家对事业单位的有关规定执行。
						</p>
						<h4><span class="jiacu">第六章</span>党建工作</h4>
						<p>
							<span class="jiacu">
								第四十三条
							</span>
							本会按照党章和《中共中央办公厅印发关于加强社会组织党的建设工作的意见（试行）》规定，凡有三名以上正式党员的社会组织，按照党章规定，经上级党组织批准，分别设立党委、总支、支部，并按期进行换届。规模较大、会员单位较多而党员人数不足规定要求的，经县级以上党委批准可以建立党委。若正式党员人数少于3名暂不具备单独建立党组织的条件，可以通过建立联合党组织或由上级党组织选派党建工作指导员等方式，在本会开展党的工作。社会组织变更、撤并或注销，党组织应及时向上级党组织报告，并做好党员组织关系转移等相关工作；上级党组织应及时对社会组织党组织变更或撤销作出决定，督促指导所属社会组织党组织按期换届，审批选出的书记、副书记，审核社会组织负责人人选，指导做好党的建设的其他工作。
						</p>
						<p><span
								class="jiacu">第四十四条</span>本会党组织是党在社会组织中的战斗堡垒，发挥政治核心作用。基本职责是保证政治方向，团结凝聚群众，推动事业发展，建设先进文化，服务人才成长，加强自身建设。
						</p>
						<h4><span class="jiacu">第七章</span>章程的修改程序</h4>
						<p><span class="jiacu">第四十五条</span>对本会章程的修改，须经理事会表决通过后报会员大会审议。</p>
						<p><span class="jiacu">第四十六条</span>本会修改的章程，须在会员大会通过后30日内，报登记管理机关核准后生效。</p>
						<h4><span class="jiacu">第八章</span>终止程序及终止后的财产处理</h4>
						<p><span class="jiacu">第四十七条</span>本会有以下情形之一，应当终止，并由理事会或常务理事会提出注销动议：</p>
						<p>（一）完成章程规定的宗旨的；</p>
						<p>（二）会员大会决议解散的；</p>
						<p>（三）协会发生分立、合并的；</p>
						<p>（四）无法按照章程规定的宗旨继续开展工作的。</p>

						<p><span class="jiacu">第四十八条</span>本会终止动议须经会员代表表决通过，并报社团登记管理机关审查同意。</p>
						<p><span
								class="jiacu">第四十九条</span>本会终止前，须在社团登记管理机关及有关单位指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。协会应在清算结束之日起十五日内到登记管理机关办理注销登记手续。
						</p>
						<p><span class="jiacu">第五十条</span>本会经社团登记管理机关办理注销登记手续后即为终止。</p>
						<p><span class="jiacu">第五十一条</span>本会终止后的剩余财产，在社团登记管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业。</p>
						<h4><span class="jiacu">第九章</span>附 则</h4>
						<p><span class="jiacu">第五十二条</span>本章程经2020年1月10日会员大会表决通过。</p>
						<p><span class="jiacu">第五十三条</span>本章程的解释权属本会的理事会。</p>
						<p><span class="jiacu">第五十四条</span>本章程自登记管理机关核准之日起生效。</p>

					</div>
				</div>
				<div style="background-color: rgb(248,248,249);height: 60px;line-height: 60px;">
					<el-checkbox style="margin-left: 10px;" v-model="radio">本单位已阅读，并同意遵守广东省水利水电行业协会章程</el-checkbox>
					<el-button style="margin: 10px auto;float: right;margin-right: 10px;" @click="xyb()" type="primary">
						下一步</el-button>
				</div>

			</el-card>
		</div>
		<div v-else-if="type==3" class="bqlm">
			<el-card shadow="never">
				<div slot="header">
					<h3 style="margin-left: 20px;">我的申请</h3>
				</div>
				<el-steps :active="1" align-center>
					<el-step title="阅读入会章程"></el-step>
					<el-step title="入会申请表"></el-step>
					<el-step title="完成申请"></el-step>
				</el-steps>
				<div style="margin: 20px auto;width: 80%;">
					<el-descriptions class="margin-top" :column="5">

						<el-descriptions-item :span="5" label="单位名称">
							<el-input v-model="list.company" placeholder="请输入内容" style="width:500px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="单位税号">
							<el-input v-model="list.dutyParagraph" placeholder="请输入内容" style="width:500px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="企业种类">
							<!-- <el-input v-model="list.comType" placeholder="请输入内容" style="width:500px;"></el-input> -->
							<el-select v-model="list.comType" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-descriptions-item>
						<el-descriptions-item :span="2" label="在职人数">
							<el-input v-model="list.comArea" style="width: 150px;" placeholder="请输入内容">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="3" label="单位邮箱">
							<el-input v-model="list.comEmail" placeholder="请输入内容" style="width: 250px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="拥有资质">
							<el-input v-model="list.comZizhi" placeholder="请输入内容" style="width:500px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="单位简介">
							<el-input v-model="list.comAbout" placeholder="请输入内容" style="width:500px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="业务范围">
							<el-input v-model="list.comServer" placeholder="请输入内容" style="width:500px;">
							</el-input>
						</el-descriptions-item>

					</el-descriptions>

					<el-descriptions direction="vertical" style="margin-top: 10px;" title="法定代表人" class="margin-top"
						:column="4">

						<el-descriptions-item label="姓名">
							<el-input v-model="list.comName1" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="职务">
							<el-input v-model="list.comJobs1" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="手机">
							<el-input v-model="list.comPhone1" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="办公电话">
							<el-input v-model="list.comMobile1" style="width:120px;">
							</el-input>
						</el-descriptions-item>

					</el-descriptions>

					<el-descriptions direction="vertical" style="margin-top: 10px;" title="会员代表" class="margin-top"
						:column="4">

						<el-descriptions-item label="姓名">
							<el-input v-model="list.comName2" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="职务">
							<el-input v-model="list.comJobs2" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="手机">
							<el-input v-model="list.comPhone2" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="办公电话">
							<el-input v-model="list.comMobile2" style="width:120px;">
							</el-input>
						</el-descriptions-item>

					</el-descriptions>

					<el-descriptions direction="vertical" style="margin-top: 10px;" title="联系人" class="margin-top"
						:column="4">

						<el-descriptions-item label="姓名">
							<el-input v-model="list.comName3" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="职务">
							<el-input v-model="list.comJobs3" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="手机">
							<el-input v-model="list.comPhone3" style="width:120px;">
							</el-input>
						</el-descriptions-item>
						<el-descriptions-item label="办公电话">
							<el-input v-model="list.comMobile3" style="width:120px;">
							</el-input>
						</el-descriptions-item>

					</el-descriptions>

					<el-descriptions style="margin-top: 20px;" class="margin-top" :column="5">
						<el-descriptions-item :span="5" label="通讯地址">
							<el-input v-model="list.comAddress" placeholder="请输入内容" style="width:500px;">
							</el-input>
							{{ res.comAddress }}
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="申请等级">
							<el-select v-model="list.comRank" placeholder="请选择">
								<el-option v-for="item in optionsto" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-descriptions-item>
						<!-- <el-descriptions-item :span="5" label="盖章附件">
							<a :href="res.comWordUrl">{{ res.comWord }}</a>
						</el-descriptions-item>
						<el-descriptions-item :span="5" label="审核状态">
							<span v-if="res.comShow != 0" style="color: green;">已审核</span>
							<span v-else style="color: red;">
								未审核
							</span>
						</el-descriptions-item> -->
					</el-descriptions>
					<p><el-button @click="dygz" style="margin: 10px auto;margin-right: 10px;" type="primary">
							下载打印盖章</el-button></p>
					
					<p style="font-weight: 600;margin: 20px 0;">单位承诺:</p>
					<p>本单位（或本人）自愿成为“广东省水利水电行业协会”会员，</p>
					<p>遵守该社会团体章程和各项规章制度，履行该社会团体赋予的权利和义务。</p>
					<p>单位（单位盖章、法定代表人签名）</p>
					<div class="block" style="margin: 20px 0;">
						<el-date-picker v-model="list.date" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>

					<p style="font-weight: 600;margin: 20px 0;">审定意见:</p>
					<p>广东省水利水电行业协会</p>
					<div class="block" style="margin: 20px 0;">
						<el-date-picker v-model="list.date2" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>

					<p style="font-weight: 600;margin-bottom: 20px;">上传盖章附件(PDF格式或压缩包):</p>
					<el-upload class="upload-demo" action="/api/sysFileInfo/upload?secretFlag=N"
						:on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1"
						:on-exceed="handleExceed" :file-list="fileList" :on-success="handleAvatarSuccess">
						<el-button size="small" type="primary">点击上传</el-button>
						<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
					</el-upload>
					<div>
						<el-button style="margin: 10px auto;margin-right: 10px;" @click="onmint()" type="primary">
							提交</el-button>
					</div>
					<div style="font-size: 14px;margin-top: 20px;">
						<p>1.会员代表为能代表单位出席协会活动，履行协会义务，热心协会事务的人员；</p>

						<p>2.联系人为能及时传达协会信息的人员，建议由贵单位指定专人任职，并加入协会QQ群；</p>
						<p>QQ群号1：416468368</p>
						<p>QQ群号2：298232928</p>
						<p>3.单位负责人、会员代表、联系人需由单位3名不同人员担任；</p>
						<p>4.单位名称、单位地址、法定代表人、会员代表、联系人等信息如有变动，请在变更后10个工作日内上系统更改。</p>
					</div>

				</div>
			</el-card>
		</div>
		<div v-else-if="type==4">
			<el-card shadow="never">
				<div slot="header">
					<h3 style="margin-left: 20px;">我的申请</h3>
				</div>
				<el-steps :active="2" align-center>
					<el-step title="阅读入会章程"></el-step>
					<el-step title="入会申请表"></el-step>
					<el-step title="完成申请"></el-step>
				</el-steps>
				<div style="margin: 20px auto;width: 80%;text-align: center;">
					<i class="el-icon-circle-check"
						style="font-size: 100px;color: rgb(55,187,54);margin-top: 60px;"></i>
					<p style="margin-top: 20px;">恭喜！您已提交入会申请</p>
					<p style="margin-top: 10px;">请耐心等待工作人员后台审核，感谢！</p>
					<router-link style="text-decoration: none;color:  inherit;" :to="'/membersto/application'">
						<el-button style="margin: 10px auto;" type="primary">
							返回，查看我的申请</el-button>
					</router-link>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		editUserInfo,
		getpublic
	} from '../../api/interface.js'
	export default {
		data() {
			return {
				fileList: [],
				res: {},
				type: 1,
				radio: false,
				list: {
					company: '',
					dutyParagraph:'',
					comType: '',
					comArea: '',
					comEmail: '',
					comZizhi: '',
					comAbout: '',
					comServer: '',
					comName1: '',
					comJobs1: '',
					comPhone1: '',
					comMobile1: '',
					comName2: '',
					comJobs2: '',
					comPhone2: '',
					comMobile2: '',
					comName3: '',
					comJobs3: '',
					comPhone3: '',
					comMobile3: '',
					comAddress: '',
					comRank: '',
					comWord: ''
				},
				options: [{
					value: '合资',
					label: '合资'
				}, {
					value: '独资',
					label: '独资'
				}, {
					value: '国有',
					label: '国有'
				}, {
					value: '私营',
					label: '私营'
				}, {
					value: '全民所有制',
					label: '全民所有制'
				}, {
					value: '集体所有制',
					label: '集体所有制'
				}, {
					value: '股份制',
					label: '股份制'
				}, {
					value: '有限责任制',
					label: '有限责任制'
				}],
				optionsto: [{
					value: '副会长单位',
					label: '副会长单位'
				}, {
					value: '常任理事单位',
					label: '常任理事单位'
				}, {
					value: '监事长单位',
					label: '监事长单位'
				}, {
					value: '理事单位',
					label: '理事单位'
				}, {
					value: '监事单位',
					label: '监事单位'
				}, {
					value: '一般会员单位',
					label: '一般会员单位'
				}],
			}
		},
		computed: {
			...mapState(['isLogin', 'loginInfo'])
		},
		created() {
			this.type = 1

		},

		methods: {
			dygz() {
				getpublic({}).then(res => {
					window.open(res.data.data.config.stamp_template)
					// window.location.href=res.data.data.config.stamp_template

				}).catch(() => {
					this.$message.error('请求出错，请联系管理员');
				})
			},
			handleAvatarSuccess(res, file) {
				// this.imageUrl = URL.createObjectURL(file.raw);
				this.list.comWord = res.data.fileId
				console.log(res, file)
			},
			onmint() {
				let login = JSON.parse(this.loginInfo).token
				editUserInfo({
					token: login,
					company: this.list.company,
					dutyParagraph: this.list.dutyParagraph,
					comType: this.list.comType,
					comArea: this.list.comArea,
					comEmail: this.list.comEmail,
					comZizhi: this.list.comZizhi,
					comAbout: this.list.comAbout,
					comServer: this.list.comServer,
					comName1: this.list.comName1,
					comJobs1: this.list.comJobs1,
					comPhone1: this.list.comPhone1,
					comMobile1: this.list.comMobile1,
					comName2: this.list.comName2,
					comJobs2: this.list.comJobs2,
					comPhone2: this.list.comPhone2,
					comMobile2: this.list.comMobile2,
					comName3: this.list.comName3,
					comJobs3: this.list.comJobs3,
					comPhone3: this.list.comPhone3,
					comMobile3: this.list.comMobile3,
					comAddress: this.list.comAddress,
					comRank: this.list.comRank,
					comWord: this.list.comWord
				}).then(res => {
					// if(res.data.data)
					if (res.data.success) {
						this.type = 4
					} else {
						this.$message(res.data.message)
					}
				})
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				console.log(file, fileList)
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			xyb() {
				if (this.radio) {
					this.type = 3
				} else {
					this.$message('未勾选须知条款')
				}
			}
		}
	}
</script>

<style>
	.memship .el-descriptions__body {
		background-color: rgb(248, 248, 249);
		padding: 20px;
		margin-top: 40px;
	}

	.jiacu {
		font-weight: 600;
	}

	.bqlm .el-descriptions .is-bordered .el-descriptions-item__cell {
		text-align: center;
		width: 180px;
	}
</style>
