import VueRouter from 'vue-router'

import Index from '../views/index.vue'
import cIndex from '../components/index.vue'


import left_bt from '../components/left_bt.vue'
import rigth_bt from '../components/rigth_bt.vue'

import members from '../components/members.vue'
import registered from '../components/members/registered.vue'
import login from '../components/members/login.vue'
import search from '../components/search.vue'

import membersto from '../components/membersto.vue'
import application from '../components/membersto/application.vue'
import membership from '../components/membersto/membership.vue'
import download from '../components/membersto/download.vue'
import exit from '../components/membersto/exit.vue'
//这里就是配置路由的 规则 先配置

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


// list;

//路由规则
const routes = [{
	path: '/',
	component: Index,
	redirect: '/cIndex',
	children: [{
			path: 'cIndex',
			component: cIndex
		},
		{
			path: "/members",
			component: members,
			redirect: '/members/login',
			children: [{
				path: '/members/login',
				component: login,
			}, {
				path: '/members/registered',
				component: registered,
			}]
		},
		{
			path: "/membersto",
			component: membersto,
			redirect: '/membersto/membership',
			children: [{
				path: '/membersto/download',
				component: download,
			}, {
				path: '/membersto/membership',
				component: membership,
			}, {
				path: '/membersto/exit',
				component: exit,
			}, {
				path: '/membersto/application',
				component: application,
			}]
		},
		{
			path: "/search",
			component: search,
		},
		{
			path: "/:id",
			component: left_bt,
			redirect: '/:id/list',
			children: [{
					path: '/:id/:chId',
					component: rigth_bt,
				},

			]
		},

	]
}]


//可能是 这个地址是动态 获取的 动态获取两层 我昨天可以 


const router = new VueRouter({
	mode: "history",
	routes // (缩写) 相当于 routes: routes
})

export default router
