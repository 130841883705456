import Vue from 'vue';
import axios from "axios";

//这里就是 配置一个 请求的基本信息

// const baseURL = location.hostname === 'localhost' ? '/' : 'http://106.14.118.168:8080';

const baseURL = '/'

const instance = axios.create({
	//基础路径 后面的请求 前面都会加上这个
	// baseURL: 'http://106.14.118.168:8080', 因为要跨域 这里配置了基础路径 所以发送请求的时候 是请求的 http://106.14.118.168:8000/api/index 不是本地地址发送的
	baseURL,
	timeout: 50000,
	// http协议的配置信息
	// headers: {'X-Custom-Header': 'foobar'}
});
//请求拦截
instance.interceptors.request.use(function(config) {
	//这个 config 就是请求的配置 可以配置每一次 请求需要设置什么 
	// console.log(config,'>>>') 
	//config.headers 你可以添加 你想要要的东西 在这里你可以 带上token啥的 
	// Do something before request is sent
	return config;
}, function(error) {
	// Do something with request error
	return Promise.reject(error);
});

//响应拦截
instance.interceptors.response.use(function(response) {

	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function(error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

Vue.prototype.http = instance;

export default instance;
