<template>
	<div>
		<div class="members-header">
			<!-- <div class="members-header-div">
				<el-row>
					<el-col :span="1">
						<el-image style="width: 24px;height: 24px;margin: 8px 0 0 20px;"
							:src="require('../assets/views/icon_news.png')">
						</el-image>

					</el-col>
					<el-col style="color: #fff;" :span="23">
						重要通知：关于无人可敌嗯嗯呢i点击>>
					</el-col>
				</el-row>
			</div> -->
			<div class="members-header-div">
				<el-row>
					<el-col :span="1">
						<el-image style="width: 24px;height: 24px;margin: 8px 0 0 20px;"
							:src="require('../assets/views/icon_news.png')">
						</el-image>

					</el-col>
					<el-col :span="23">
						<el-carousel height="40px" direction="vertical" indicator-position="none">
							<el-carousel-item v-for="(item,index) in list_info" :key="index">
								<!-- <h3 class="medium">{{ index }}</h3> -->
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/1/2?mid='+item.id">
									<span style="color: #fff;">{{ item.name }}</span>
								</router-link>
							</el-carousel-item>
						</el-carousel>

						<!-- 重要通知：关于无人可敌嗯嗯呢i点击>> -->
					</el-col>
				</el-row>
			</div>
		</div>
		<div v-loading="loading" style="width: 1170px;margin: 20px auto;" class="members-tabs">
			<el-row :gutter="60">
				<el-col :span="7">
					<el-menu @select="sele" :default-active="path" @open="handleOpen" @close="handleClose" router>
						<span v-if="list.moduleModels[0].catName !== undefined && list.moduleModels[0].catName.length > 10">
							<el-menu-item index="" disabled style="height: 80px;">
								<el-image style="width: 26px;margin-right: 10px;height: 26px;"
									:src="list.moduleModels[0].imageUrl">
									<div slot="error" class="image-slot">
									
									</div>
								</el-image>
								<span style="color: #FFFFFF;display: inline-block;width: 100%;line-height: 30px;margin-top: 10px;" slot="title">{{list.moduleModels[0].catName.slice(0,10)}}<br>{{list.moduleModels[0].catName.slice(11)}}</span>
							</el-menu-item>
						</span>
						<span v-else>
							<el-menu-item index="" disabled>
								<el-image style="width: 26px;margin-right: 10px;height: 26px;"
									:src="list.moduleModels[0].imageUrl">
									<div slot="error" class="image-slot">
									
									</div>
								</el-image>
								<span style="color: #FFFFFF;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;width: 100%;" slot="title">{{list.moduleModels[0].catName}}</span>
							</el-menu-item>
						</span>
						

						<span v-for="(item,index) in res" :key="index">
							<el-submenu :index="item.catId" v-if="item.modelList.length">
								<template slot="title">
									<!-- <el-image style="width: 18px;margin-right: 10px;height: 18px;"
										:src="item.imageUrl">
										<div slot="error" class="image-slot">
											
										</div>
									</el-image> -->
									<el-image style="height: 15px;margin-right: 10px;width: 15px;"
										:src="require('../assets/views/icon_list@2x-1.png')">
									</el-image>
									<span>{{item.catName}}</span>
								</template>
								<el-menu-item :index="'/'+item.parentId+'/'+itemto.catId"
									v-for="(itemto,indexto) in item.modelList" :key="indexto">
									<template slot="title">
										<el-image style="height: 15px;margin-right: 10px;width: 15px;"
											:src="require('../assets/views/icon_list@2x-1.png')">
										</el-image>
										<span>{{itemto.catName}}</span>
									</template>
								</el-menu-item>
							</el-submenu>
							<el-menu-item :index="'/'+item.parentId+'/'+item.catId" v-if="!item.modelList.length">
								<template slot="title">
									<el-image style="height: 15px;margin-right: 10px;width: 15px;"
										:src="require('../assets/views/icon_list@2x-1.png')">
									</el-image>
									<span>
										{{item.catName}}
									</span>
								</template>
							</el-menu-item>

						</span>

					</el-menu>
				</el-col>
				<el-col :span="17">
					<router-view></router-view>
				</el-col>
			</el-row>
		</div>
	</div>

</template>

<script>
	import {
		getArticleCategoryList
	} from '../api/interface.js'
	export default {
		data() {
			return {
				loading: false,
				// arr:['2','3'],
				path: "",
				list: {
					moduleModels: [{}]
				},
				res: [],
				list_info: [],
			}
		},

		created() {
			this.loading = true
			this.onRouteChanged()
			this.getMenuList();
		},
		watch: {
			//监剛路由变化，只要变化7就调用获职路由参数方法将数据存储本组件即可
			'$route.params.id'() {
				this.loading = true
				this.onRouteChanged();
				this.getMenuList();
			},
			'$route.params.chId'() {
				this.onRouteChanged();
				this.getMenuList();
			},
			
		},
		methods: {
			sele(key){
				// console.log(this.$route.path)
				if(key == this.$route.path){
					// console.log(keyPath)
					this.$router.push('./cIndex');
				}
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			onRouteChanged() {
				let that = this
				// console.log(that.$route.path)
				that.path = that.$route.path;
				
			},

			getMenuList() {
				getArticleCategoryList({
					catId: 2,
					pageNo: 1,
					pageSize: 3
				}).then(res => {
					this.list_info = res.data.data.articlePage.rows
					// console.log(this.list_info)
				})
				getArticleCategoryList({
					catId: this.$route.params.id
				}).then(res => {
					this.list = res.data.data;
					this.res = Object.freeze(res.data.data.moduleModels[0].modelList);
					this.$store.commit("setChildrenNavMenus", this.res)
					this.loading = false
				})
			},
		}
	}
</script>

<style>
	.members-tabs .el-tabs__nav-wrap::after {
		background-color: #fff;
	}

	.members-tabs .el-tabs__item.is-disabled {

		background-image: url(../assets/views/note_bg@2x-12.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		color: #fff;
	}

	.members-tabs .el-breadcrumb__inner,
	.members-tabs .el-breadcrumb__item:last-child .el-breadcrumb__inner,
	.members-tabs .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
	.members-tabs .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
	.members-tabs .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
		color: rgb(48, 117, 210);
	}

	.members-tabs .el-breadcrumb {
		font-size: 20px;
	}


	.members-tabs .el-card__header {
		background-color: rgb(248, 248, 249);
	}

	.members-tabs .el-tabs__header {
		width: 20%;
	}

	.members-tabs .el-tabs__item.is-left {
		font-size: 24px;
		/* margin: 10px 0; */
		text-align: left;
	}

	.members-tabs .el-tabs__item.is-active {
		background-color: rgb(246, 251, 255);
	}

	.members-tabs .el-tabs__item {
		height: 60px;
		line-height: 60px;
	}

	.members-tabs .el-menu-item,
	.members-tabs .el-submenu__title {
		font-size: 16px;
	}

	.members-tabs .el-menu-item.is-disabled {
		background-image: url(../assets/views/note_bg@2x-12.png) !important;
		background-repeat: no-repeat !important;
		background-size: 100% 100% !important;
		color: #fff;
		opacity: 1;
		cursor: auto;
	}

	.members-tabs .el-menu-item,
	.el-submenu__title {
		height: 60px;
		line-height: 60px;
	}

	.margin-top .el-descriptions-item__label:not(.is-bordered-label) {
		font-weight: bold;
	}
</style>
