<template>
	<div class="bqlm">
		<el-card shadow="never">
			<div slot="header">
				<h3 style="margin-left: 20px;">我的申请</h3>
			</div>
			<el-descriptions class="margin-top" :column="5" border>

				<el-descriptions-item :span="5" label="单位名称">
					{{ res.company }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="单位税号">
					{{ res.dutyParagraph }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="企业种类">
					{{ res.comType }}
				</el-descriptions-item>
				<el-descriptions-item :span="3" label="在职人数">
					{{ res.comArea }}
				</el-descriptions-item>
				<el-descriptions-item :span="2" label="单位邮箱">
					{{ res.comEmail }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="拥有资质">
					{{ res.comZizhi }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="单位简介">
					{{ res.comAbout }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="业务范围">
					{{ res.comServer }}
				</el-descriptions-item>

			</el-descriptions>

			<el-descriptions style="margin-top: 10px;" title="法定代表人" class="margin-top" :column="4" border>

				<el-descriptions-item label="姓名">
					{{ res.comName1 }}
				</el-descriptions-item>
				<el-descriptions-item label="职务">
					{{ res.comJobs1 }}
				</el-descriptions-item>
				<el-descriptions-item label="手机">
					{{ res.comPhone1 }}
				</el-descriptions-item>
				<el-descriptions-item label="办公电话">
					{{ res.comMobile1 }}
				</el-descriptions-item>

			</el-descriptions>

			<el-descriptions style="margin-top: 10px;" title="会员代表" class="margin-top" :column="4" border>

				<el-descriptions-item label="姓名">
					{{ res.comName2 }}
				</el-descriptions-item>
				<el-descriptions-item label="职务">
					{{ res.comJobs2 }}
				</el-descriptions-item>
				<el-descriptions-item label="手机">
					{{ res.comPhone2 }}
				</el-descriptions-item>
				<el-descriptions-item label="办公电话">
					{{ res.comMobile2 }}
				</el-descriptions-item>

			</el-descriptions>

			<el-descriptions style="margin-top: 10px;" title="联系人" class="margin-top" :column="4" border>

				<el-descriptions-item label="姓名">
					{{ res.comName3 }}
				</el-descriptions-item>
				<el-descriptions-item label="职务">
					{{ res.comJobs3 }}
				</el-descriptions-item>
				<el-descriptions-item label="手机">
					{{ res.comPhone3 }}
				</el-descriptions-item>
				<el-descriptions-item label="办公电话">
					{{ res.comMobile3 }}
				</el-descriptions-item>

			</el-descriptions>

			<el-descriptions style="margin-top: 20px;" class="margin-top" :column="5" border>
				<el-descriptions-item :span="5" label="通讯地址">
					{{ res.comAddress }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="申请等级">
					{{ res.comRank }}
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="盖章附件">
					<span v-if="!res.comWordUrl">
						暂无文件下载
					</span>
					<span v-else>
						<a :href="res.comWordUrl">
							<el-button style="margin: 10px auto;" type="primary">
								文件下载</el-button>
						</a>
					</span>
				</el-descriptions-item>
				<el-descriptions-item :span="5" label="审核状态">
					<span v-if="res.comShow != 0" style="color: green;">已审核</span>
					<span v-else style="color: red;">
						未审核
					</span>
				</el-descriptions-item>
			</el-descriptions>
			<div style="text-align: center;">
				<router-link style="text-decoration: none;color:  inherit;" :to="'/membersto/membership'">
					<el-button style="margin: 10px auto;width: 160px;" type="primary">编辑资料</el-button>
				</router-link>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getUserInfo
	} from '../../api/interface.js'
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				res: {}
			}
		},
		computed: {
			...mapState(['isLogin', 'loginInfo'])
		},
		watch: {
		
			'loginInfo'() {
				getUserInfo({
					token: JSON.parse(this.loginInfo).token
				}).then(res => {
					// if(res.data.data)
					if (res.data.success) {
						this.res = res.data.data
					} else {
						this.$message({
							message: '请先登录！',
							type: 'warning',
							duration: 1000,
							onClose: () => {
								this.$store.commit('setIsLogin', false);
								window.sessionStorage.removeItem('userId');
								this.$router.push('/members/login')
							}
						})
				
					}
					// this.list_info = res.data.data
					// this.loading = false
				})
			},
		
		},
		created	() {
			getUserInfo({
				token: JSON.parse(this.loginInfo).token
			}).then(res => {
				// if(res.data.data)
				if (res.data.success) {
					this.res = res.data.data
				} else {
					this.$message({
						message: '请先登录！',
						type: 'warning',
						duration: 1000,
						onClose: () => {
							this.$store.commit('setIsLogin', false);
							window.sessionStorage.removeItem('userId');
							this.$router.push('/members/login')
						}
					})

				}
				// this.list_info = res.data.data
				// this.loading = false
			})

		},

		methods: {}
	}
</script>

<style>
	.bqlm .el-descriptions .is-bordered .el-descriptions-item__cell {
		text-align: center;
		width: 180px;
	}
</style>
