import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		navMenus: [], //记录顶级导航信息
		childrenNavMenus: [], //二级导航信息
		isLogin: false, //是否登录
		loginInfo: {}, //登录信息
	},
	mutations: {
		setNavInfo(state, data) {
			state.navMenus = data
		},
		setChildrenNavMenus(state, data) {
			state.childrenNavMenus = data;
		},
		setIsLogin(state, data) {
			state.isLogin = data;
		},
		setLoginInfo(state, data) {
			state.loginInfo = data
		}
	},
	actions: {
		//查询当前登录信息
		whoAmI({commit}) {
			const userInfo = window.sessionStorage.getItem('userId');
			//请求当前用户信息
			if (userInfo) {
				commit("setLoginInfo",userInfo)
				commit("setIsLogin", true)
			} else {
				commit("setLoginInfo", {})
				commit("setIsLogin", false);
				window.sessionStorage.removeItem('userId');
			}

		}
	}
})
export default store;
