<template>
	<div :style="width" v-loading="loading" style="margin: 0 auto;">
		<el-container>
			<div>
				<el-header height="120px">
					<div class="tb">
						<el-row>
							<el-col :span="18">
								<el-row>
									<el-col :span="3">
										<el-image style="width: 100px; height: 100px;margin-top: -15px;"
											:src="list.site_logo">
											<div slot="error" class="image-slot">

											</div>
										</el-image>
									</el-col>
									<el-col :span="21">
										<h1 style="font-size: 36px;">{{list.site_title}}</h1>
										<h3 style="font-size: 14px;">Guangdong Provincaial Water
											Conservancy
											And Hydropower Industry Association</h3>
									</el-col>
								</el-row>
							</el-col>
							<span v-if="!isLogin">
								<el-col :span="6">
									<el-row style="text-align: right;">
										<router-link style="text-decoration: none;color:  inherit;margin-right: 10px;"
											:to="'/members/login'">
											<el-button type="primary" plain>会员登录</el-button>
										</router-link>
										<router-link style="text-decoration: none;color:  inherit;"
											:to="'/members/registered'">
											<el-button type="primary" plain>注册</el-button>
										</router-link>
									</el-row>
								</el-col>
							</span>
							<span v-else style="font-size: 14px;text-align: right;">
								<el-col :span="6">
									您好{{ JSON.parse(loginInfo).email }}
									<el-button @click="tcdl()" type="primary" style="margin-left: 10px;" plain>退出登录
									</el-button>
								</el-col>
							</span>

						</el-row>
					</div>


				</el-header>
				<el-main>
					<div class="dh">
						<el-menu router active-text-color="#fff" text-color="#fff" :default-active="path"
							class="el-menu-demo" mode="horizontal">
							<el-menu-item index="/cIndex">
								<span slot="title">

									<span>首页</span>
								</span>
								<!-- <template slot="title"></template> -->
							</el-menu-item>
							<!-- <span v-if="item.guide == '78'">
								<el-menu-item :index="'/' + item.guide">
									{{item.navName}}
								</el-menu-item>
							</span>
							<span v-else> -->


							<el-menu-item v-for="(item,index) in res" :key="index" :index="item.url">
								<span slot="title">
									<span v-if="item.columnModels.length != 0">
										<el-popover placement="bottom" width="150px" popper-class="tcc" trigger="hover">
											<div v-for="(itemto,indexto) in item.columnModels" :key="indexto">
												<router-link style="text-decoration: none;color:  inherit;"
													:to="'/'+item.guide+'/'+itemto.catId">
													<p class="xtbs"
														style="padding: 10px 0;font-size: 16px;cursor: pointer;text-align: center;color: #fff;font-weight: 600;">

														{{itemto.catName}}

													</p>
												</router-link>
											</div>

											<span slot="reference">{{item.navName}}</span>
										</el-popover>
									</span>
									<span v-else>
										<span>{{item.navName}}</span>
									</span>
								</span>

							</el-menu-item>


							<!-- 			<el-menu-item v-for="(item,index) in res" :key="index" :index="item.url">
							{{item.navName}}
						</el-menu-item> -->
							<!-- </span> -->


							<!-- 	<el-menu-item index="/cIndex">首页</el-menu-item>
						<el-menu-item index="/survey">协会概况</el-menu-item>
						<el-menu-item index="/dynamic">协会动态</el-menu-item>
						<el-menu-item index="/fourth">标准化活动</el-menu-item>
						<el-menu-item index="/education">专业技术人员继续教育</el-menu-item>
						<el-menu-item index="/news">行业要闻</el-menu-item>
						<el-menu-item index="/regulations">政策法规</el-menu-item>
						<el-menu-item index="/members">会员天地</el-menu-item>
						<el-menu-item index="/download">下载中心</el-menu-item>
						<el-menu-item index="/contact">联系我们</el-menu-item> -->
						</el-menu>

					</div>
					<router-view></router-view>
				</el-main>
			</div>
			<el-footer>
				<div class="footer">
					<el-row type="flex" style="text-align: center;" justify="center">
						<el-col v-for="(item,index) in res_info" :key="index" :span="2">
							<span v-if="item.parentId == 0">
								<router-link style="text-decoration: none;color:  inherit;" :to="'/' + item.guide">
									{{ item.navName }}
								</router-link>
							</span>
							<span v-else>
								<router-link style="text-decoration: none;color:  inherit;"
									:to="'/' + item.parentId + '/' + item.guide">
									{{ item.navName }}
								</router-link>
							</span>
						</el-col>
					</el-row>
				</div>
				<div class="footer-bottom">
					<el-row>
						<el-col :span="2">
							<el-image style="width: 50px; height: 50px;" :src="list.site_logo">
								<div slot="error" class="image-slot">

								</div>
							</el-image>

						</el-col>
						<el-col :span="22" style="line-height: 30px;font-size: 14px;color: #666;">
							{{list.copyright}}
							<br>
							甲方:&nbsp;&nbsp;{{list.site_description}}&nbsp;&nbsp;&nbsp;&nbsp;地址:&nbsp;&nbsp;{{list.site_address}}&nbsp;&nbsp;&nbsp;&nbsp;电话:&nbsp;&nbsp;{{list.tel}}&nbsp;&nbsp;{{list.icp}}
						</el-col>
					</el-row>
				</div>
			</el-footer>

		</el-container>

	</div>
</template>

<script>
	import {
		getpublic
	} from '../api/interface.js'
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				loading: false,
				list: {},
				res: [],
				res_info: [{
					navName: ''
				}],
				path: "",
				width: {
					width: '100%'
				},
				// isLogin:false,


			}
		},
		computed: {
			...mapState(['isLogin', 'loginInfo'])
		},
		mounted() {
			// document.title = "广东省水利水电行业协会"
			var u = navigator.userAgent;
			// app = navigator.appVersion;
			// var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
			var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			// var scale = "100%"; // 分母——设计稿的尺寸
			var scale = window.screen.width / 1920; // 分母——设计稿的尺寸
			if (isIOS) {
				// console.log(window.screen.width)
				// document.body.style.zoom = 0.2;
				var height = window.screen.height
				// console.log(height)
				document.body.style.height = height + 'px'
				document.body.style.transform = 'scale(' + scale + ')';
				// document.body.style.transform = 'scale(100%)';
				document.body.style.transformOrigin = 'left top';
				window.onresize = () => {
					return (() => {

						document.body.style.transform = 'scale(' + scale + ')';
						// document.body.style.transform = 'scale(100%)';
					})();
				};
				// console.log(document.body.style)
				// console.log(1)
			} else {
				// console.log(window.screen.width)
				// console.log(scale)

				document.body.style.zoom = scale;
				window.onresize = () => {
					return (() => {
						document.body.style.zoom = scale;
					})();
				};
			}
			// this.width.width = '1920px'
			// var scale = window.screen.width / 1920; // 分母——设计稿的尺寸
			// // console.log(window.screen.width)
			// // console.log(scale)

			// document.body.style.zoom = scale;
			// window.onresize = () => {
			// 	return (() => {
			// 		var scale = window.screen.width / 1920; // 分母——设计稿的尺寸

			// 		document.body.style.zoom = scale;
			// 	})();
			// };

		},
		created() {
			// this.width.width = window.screen.width + 'px'


			this.onRouteChanged()
			this.loading = true
			getpublic({}).then(res => {
				this.res = []
				this.res_info = []
				this.list = res.data.data.config
				this.res = res.data.data.navModels
				this.res_info = res.data.data.bottomModels
				for (let i = 0; i < this.res.length; i++) {
					if (this.res[i].guide == "78") {
						this.res[i].url = "/" + this.res[i].guide + "/32"
					} else {
						this.res[i].url = "/" + this.res[i].guide
					}
				}

				// console.log(this.res_info)
				// this.res = Object.freeze(res.data.data.navModels);
				this.$store.commit('setNavInfo', this.res)
				this.loading = false
				// console.log(this.res, '============')
			}).catch(() => {
				this.$message.error('请求出错，请联系管理员');
			})
			//第二种
			// const result = await getMenuList({});
			//这下面的代码 相当于上面的 then  就是下面的代码 需要请求结束后才会执行
		},
		watch: {
			//监剛路由变化，只要变化7就调用获职路由参数方法将数据存储本组件即可
			'$route': 'onRouteChanged',
		},
		//注册 组件页面才能使用
		// components: {
		// 	Member
		// },
		methods: {
			tcdl() {
				this.$message({
					message: '退出成功！',
					type: 'success',
					duration: 1000,
					onClose: () => {
						this.$store.commit('setIsLogin', false);
						window.sessionStorage.removeItem('userId');
					}
				})

			},
			onRouteChanged() {
				let that = this
				if (that.$route.path.split("/")[1] == '78') {
					that.path = "/" + that.$route.path.split("/")[1] + "/32";
				} else {
					that.path = "/" + that.$route.path.split("/")[1];
				}
				// console.log(that.path)
			}
		}
	}
</script>

<style>
	.tb {
		width: 1170px;
		margin: 40px auto;
	}

	.footer {
		height: 60px;
		line-height: 60px;
		background-color: rgb(34, 72, 115);
		color: #fff;
		width: 100%;
	}

	.footer-bottom {
		width: 45%;
		margin: 20px auto;
	}

	.dh .el-menu-item {
		font-size: 16px;
	}

	.dh .el-menu {
		background-color: rgb(64, 158, 255);
	}

	.dh .el-menu.el-menu--horizontal {
		border: none;
	}

	.dh .el-menu-item:hover {

		background-color: rgb(255, 255, 255,0.2) !important;

	}

	.dh .el-submenu__title:hover {
		background: rgb(64, 158, 255) !important;
	}

	.dh .el-menu-item.is-active {
		background: #6db6ff !important;
	}

	.dh .el-submenu__title.is-active {
		background: #6db6ff !important;
	}

	.dh {
		background-color: rgb(64, 158, 255);
	}

	.dh .el-menu {
		/* display: flex;
		justify-content: center; */
		/* display: table; */
		margin: 0 auto;
		width: 1170px;
	}



	.dh .el-menu li {
		/* display: table-cell; */
		/* width: 8%; */
		/* margin: 0 auto; */
		/* list-style-type: none;
		text-align: center; */
	}

	.xtbs:hover {
		color: #fff;
		background-color: rgb(255,255,255,0.3);
	}



	.tcc {
		background-color: rgb(7, 177, 255);
	}

	.el-popover {
		border: 0;
	}

	.el-popper[x-placement^=bottom] .popper__arrow::after {
		border-bottom-color: rgb(7, 177, 255);
	}
</style>
