<template>
	<div class="downl">
		<el-card shadow="never">
			<el-descriptions class="margin-top" :column="5" border>
				<el-descriptions-item :span="5" label="会员证书">
					<span v-if="!certificateUrl">
						暂无文件下载
					</span>
					<span v-else>
						<a :href="certificateUrl" download>
							<el-button style="margin: 10px auto;" type="primary">
								文件下载</el-button>
						</a>
					</span>
					

				</el-descriptions-item>
				<el-descriptions-item :span="5" label="会费票据">
					<span v-if="!billUrl">
						暂无文件下载
					</span>
					<span v-else>
						<a :href="billUrl" download>
							<el-button style="margin: 10px auto;" type="primary">
								文件下载</el-button>
						</a>
					</span>
					
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
	</div>
</template>

<script>
	import {
		getUserInfo
	} from '../../api/interface.js'
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				res: {},
				certificateUrl:'',
				billUrl:''
			}
		},
		computed: {
			...mapState(['isLogin', 'loginInfo'])
		},
		created() {
			getUserInfo({
				token: JSON.parse(this.loginInfo).token
			}).then(res => {
				// if(res.data.data)
				if (res.data.success) {
					this.certificateUrl = res.data.data.certificateUrl
					this.billUrl = res.data.data.billUrl
				} else {
					this.$message({
						message: '请先登录！',
						type: 'warning',
						duration: 1000,
						onClose: () => {
							this.$store.commit('setIsLogin', false);
							window.sessionStorage.removeItem('userId');
							this.$router.push('/members/login')
						}
					})

				}
				// this.list_info = res.data.data
				// this.loading = false
			})

		},

		methods: {}
	}
</script>

<style>
	.downl .el-descriptions .is-bordered .el-descriptions-item__cell {
		text-align: center;
		width: 180px;
	}
</style>
