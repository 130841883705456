//可以所有的请求写在 一起方便管理
import http from './index.js' //导入请求包
/**
 * 首页
 * 导出一个函数 用来请求 列表
 */
export async function getMenuList(params) {
	return http.post('/api/index', params)
}

/**
 * 公共
 * 导出一个函数 用来请求 列表
 */
export async function getpublic(params) {
	return http.post('/api/publicPart', params)
}

/**
 * 获取文章列表
 * 导出一个函数 用来请求 列表
 */
export async function getArticleCategoryList(params) {
	return http.post('/api/getArticleCategoryList', params)
}

/**
 * 获取文章列表
 * 导出一个函数 用来请求 列表
 */
export async function getArticleDetail(params) {
	return http.post('/api/getArticleDetail', params)
}

/**
 * 注册
 * 导出一个函数 用来请求 列表
 */
export async function register(params) {
	return http.post('/api/register', params)
}

/**
 * 登录
 * 导出一个函数 用来请求 列表
 */
export async function login(params) {
	return http.post('/api/login', params)
}

/**
 * 登录
 * 导出一个函数 用来请求 列表
 */
export async function findArticlePage(params) {
	return http.post('/api/findArticlePage', params)
}


/**
 * 获取会员信息
 * 导出一个函数 用来请求 列表
 */
export async function getUserInfo(params) {
	return http.post('/api/getUserInfo', params)
}

/**
 * 编辑会员信息
 * 导出一个函数 用来请求 列表
 */
export async function editUserInfo(params) {
	return http.post('/api/editUserInfo', params)
}